import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getStatesRequest = () => ({
  type: 'GET_STATES_REQUEST',
})

export const getStatesSuccess = (statesData) => ({
  type: 'GET_STATES_SUCCESS',
  statesData,
})

export const getStatesFailure = (error) => ({
  type: 'GET_STATES_FAILURE',
  error,
})

export const getStates = (body) => {
  return (dispatch) => {
    dispatch(getStatesRequest())

    fetch(URL.BASE_URL + URL.END_POINT.states + body, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((statesData) => {
        dispatch(getStatesSuccess(statesData))
      })
      .catch((error) => {
        dispatch(getStatesFailure(error.message))
        // toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  statesData: null,
  loadingStates: false,
  errorStates: null,
}

export const statesReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_STATES_REQUEST':
      return {
        ...state,
        loadingStates: true,
        errorStates: null,
      }
    case 'GET_STATES_SUCCESS':
      return {
        ...state,
        statesData: action.statesData,
        loadingStates: false,
        errorStates: null,
      }
    case 'GET_STATES_FAILURE':
      return {
        ...state,
        loadingStates: false,
        errorStates: action.error,
      }
    default:
      return state
  }
}
