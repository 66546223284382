import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCol,
  CContainer,
  CFormInput,
  CFormSelect,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormCheck,
  CFormSwitch,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilInfo,
  cilPencil,
  cilPeople,
  cilUser,
  cilUserFemale,
  cilUserPlus,
  cilXCircle,
} from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteAppUser, deleteMultipleAppUsers, getAppUsers } from 'src/redux/appusers'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Pagination from 'react-js-pagination'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import { AppSpinner } from '../../../components'
import { DateTimeConverter } from 'src/utils/DateTimeConverter'
import { excelFileNamer } from 'src/utils/excelFileNamer'
import { useTableSelection } from 'src/utils/useTableSelection'
import URL from 'src/apis/URL'
import { toast } from 'react-toastify'

const Appusers = () => {
  const [loading, setLoading] = useState(false)
  const [gender, setGender] = useState('Male')
  const [visibleLg, setVisibleLg] = useState(false)
  const [isFreeEntry, setIsFreeEntry] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const dispatch = useDispatch()
  const history = useNavigate()
  const itemsPerPage = 20

  const { appUsers, isLoading } = useSelector((state) => {
    return { appUsers: state.appusers.appUsers, isLoading: state.appusers.loadingAppUsers }
  })

  const { selectedItems, toggleItemSelection, toggleSelectAll, isAllSelected } = useTableSelection(
    appUsers?.app_users,
  )

  const totalItems = appUsers?.total || 0

  useEffect(() => {
    dispatch(getAppUsers(gender, currentPage, status, search))
  }, [dispatch, loading, gender, currentPage, status, search])

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber)

  async function handleDelete(id) {
    setLoading(true)
    try {
      await dispatch(deleteAppUser(id))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteButton = () => {
    dispatch(deleteMultipleAppUsers(selectedItems))
  }

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetch(URL.BASE_URL + URL.END_POINT.adminSetting, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            USERID: localStorage.getItem('USERID'),
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        const data = await response.json()
        if (response.status === 200) {
          if ('isFreeEntry' in data.admin) setIsFreeEntry(data.admin.isFreeEntry === 1)
          else setIsFreeEntry(false)
        }
        console.log(data, response.status)
      } catch (error) {}
    })()
  }, [])

  const handleSwitchToggle = async (e) => {
    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.adminSetting, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          USERID: localStorage.getItem('USERID'),
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ isFreeEntry: e.target.checked }),
      })
      const data = await response.json()
      if (response.status === 200) {
        toast(data.message)
        setIsFreeEntry(!e.target.checked)
      }
      console.log(data, response.status)
    } catch (error) {}
  }

  return (
    <CContainer>
      <CRow>
        <CCol sm={12} md={6} lg={3} className="mb-2">
          <CFormInput
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size="sm"
          />
        </CCol>
        <CCol sm={12} md={6} lg={3} className="mb-2">
          <CFormSelect
            size="sm"
            color="info"
            options={[
              { label: 'Status', value: '', disabled: true },
              { label: 'Pending', value: 'Pending' },
              { label: 'Registered', value: 'Registered' },
            ]}
            value={status}
            onChange={(event) => setStatus(event.target.value)}
          />
        </CCol>
        <CCol sm={12} md={6} lg={3} className="mb-2">
          <CButton
            color="success"
            size="sm"
            className="text-white w-100"
            onClick={() => setVisibleLg(!visibleLg)}
          >
            Export All Page
          </CButton>
        </CCol>

        <CCol sm={12} md={6} lg={3} className="mb-2">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button w-100 btn-sm btn btn-success text-white"
            color="success"
            table="table-to-xls-current"
            filename={excelFileNamer('Current')}
            sheet="tablexls"
            buttonText="Export Current Page"
          />
        </CCol>

        <CCol sm={12} md={6} lg={3} className="mb-2">
          <CButton
            color="info"
            size="sm"
            className="text-white w-100"
            onClick={() => history('/users/new')}
          >
            Create new account <CIcon icon={cilUserPlus} className="text-white" />
          </CButton>
        </CCol>
        <CCol sm={12} md={6} lg={3} className="mb-2">
          <CButtonGroup className="w-100">
            {['Male', 'Female'].map((value) => (
              <CButton
                color="outline-primary"
                key={value}
                active={value === gender}
                size="sm"
                onClick={() => {
                  setGender(value)
                  setCurrentPage(1)
                }}
              >
                <CIcon icon={value === 'Female' ? cilUserFemale : cilUser} /> {value}
              </CButton>
            ))}
          </CButtonGroup>
        </CCol>
        <CCol sm={12} md={6} lg={3} className="mb-2">
          <CButton
            color="danger"
            size="sm"
            className="text-white w-100"
            onClick={() => {
              setStatus('')
              setSearch('')
            }}
          >
            Clear Filters
          </CButton>
        </CCol>

        <CCol sm={12} md={6} lg={3} className="mb-2">
          <CButton
            color="danger"
            size="sm"
            className="text-white w-100"
            onClick={handleDeleteButton}
            disabled={selectedItems.length === 0}
          >
            Delete
          </CButton>
        </CCol>
        <CCol sm={12} md={6} lg={3} className="mb-2">
          <div className="d-flex justify-content-between align-items-center">
            <span className="d-flex">Free Joining (Females)</span>
            <CFormSwitch id="freeJoining" checked={isFreeEntry} onChange={handleSwitchToggle} />
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm={12}>
          {isLoading ? (
            <CContainer className="d-flex justify-content-center align-items-center">
              <AppSpinner />
            </CContainer>
          ) : (
            <PerfectScrollbar style={{ height: '100%' }}>
              <CTable
                id="table-to-xls-current"
                align="middle"
                className="mb-3 border"
                hover
                responsive
              >
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell>
                      <CFormCheck
                        checked={isAllSelected}
                        onChange={toggleSelectAll}
                        color="primary"
                      />
                    </CTableHeaderCell>
                    <CTableHeaderCell className="text-center">
                      <CIcon icon={cilPeople} />
                    </CTableHeaderCell>
                    <CTableHeaderCell>Username</CTableHeaderCell>
                    <CTableHeaderCell>Age</CTableHeaderCell>
                    <CTableHeaderCell>Mobile</CTableHeaderCell>
                    <CTableHeaderCell>Email Address</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Joining Date</CTableHeaderCell>
                    <CTableHeaderCell>Nationality & Address</CTableHeaderCell>
                    <CTableHeaderCell>Status</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {appUsers?.app_users?.map(
                    (item, index) =>
                      item.sex === gender && (
                        <CTableRow v-for="item in tableItems" key={index}>
                          <CTableDataCell>
                            <CFormCheck
                              checked={selectedItems.includes(item.id)}
                              onChange={() => toggleItemSelection(item.id)}
                              color="primary"
                            />
                          </CTableDataCell>
                          {/* <CTableDataCell>{index + 1})</CTableDataCell> */}
                          <CTableDataCell className="text-center">
                            <div className="image-size">
                              <CAvatar
                                size="md"
                                src={item.my_selfie}
                                className="image-profile"
                                // status={item.is_online === 1 ? 'success' : 'danger'}
                              />
                            </div>
                          </CTableDataCell>
                          <CTableDataCell>
                            <div>{item.name}</div>
                            <div className="small text-medium-emphasis">
                              <strong>{item.username} </strong>
                              {item.sex === 'Female' ? (
                                <CIcon icon={cilUserFemale} />
                              ) : (
                                <CIcon icon={cilUser} />
                              )}{' '}
                              {item.sex}
                            </div>
                          </CTableDataCell>
                          <CTableDataCell>
                            <div className="small text-medium-emphasis">
                              <strong>{item.age}</strong>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <small className="text-medium-emphasis">{item.mobile_no}</small>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell>
                            <div className="clearfix">
                              <small className="text-medium-emphasis">{item.email_id}</small>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <small className="text-medium-emphasis">
                                {DateTimeConverter(item.createdAt)}
                              </small>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell>
                            <div className="small text-medium-emphasis">{item.nationality}</div>
                            <small>
                              {item.city}, {item.state}, {item.country}
                            </small>
                          </CTableDataCell>
                          <CTableDataCell>
                            <div className="small text-medium-emphasis">{item.registration}</div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <CButtonGroup role="group" aria-label="Basic mixed styles example">
                              <CButton
                                color="warning"
                                size="sm"
                                onClick={() => history(`/users/edit/${item.user_code}`)}
                              >
                                <CIcon icon={cilPencil} className="text-white" />
                              </CButton>
                              <CButton
                                color="danger"
                                size="sm"
                                onClick={() => handleDelete(item.user_code)}
                              >
                                <CIcon icon={cilXCircle} className="text-white" />
                              </CButton>
                              <CButton
                                color="success"
                                size="sm"
                                onClick={() => history(`/users/${item.user_code}`)}
                              >
                                <CIcon icon={cilInfo} className="text-white" />
                              </CButton>
                            </CButtonGroup>
                          </CTableDataCell>
                        </CTableRow>
                      ),
                  )}
                </CTableBody>
              </CTable>
            </PerfectScrollbar>
          )}
        </CCol>
      </CRow>

      {!isLoading && (
        <ul className="pagination justify-content-center">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItems}
            hideFirstLastPages={false}
            onChange={handlePageChange}
            pageRangeDisplayed={5}
            nextPageText="Next"
            prevPageText="Previous"
            lastPageText="Last"
            firstPageText="1st"
            itemClass="page-item"
            linkClass="page-link"
            activeClass="active"
            activeLinkClass="active"
            hideDisabled={false}
          />
        </ul>
      )}
      <CModal
        size="xl"
        visible={visibleLg}
        onClose={() => setVisibleLg(false)}
        aria-labelledby="OptionalSizesExample2"
      >
        <CModalHeader>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn-sm btn btn-success text-white"
            color="success"
            table="table-to-xls"
            filename={excelFileNamer('All')}
            sheet="tablexls"
            buttonText="Export to excel"
          />
        </CModalHeader>
        <CModalBody>
          <CCol md={12} lg={12} xl={12}>
            <CTable id="table-to-xls" align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell className="text-center">Sr.</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Username</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Name</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Age</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Mobile No</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Email Address</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Joining_Date_Time</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Full Address</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {appUsers?.app_users?.map((item, index) => (
                  <CTableRow v-for="item in tableItems" key={item.id}>
                    <CTableDataCell className="text-center">
                      <div>{index + 1})</div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div>{item.username}</div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div>{item.name}</div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div>{item.age}</div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div>{item.mobile_no}</div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div>{item.email_id}</div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div>{DateTimeConverter(item.createdAt)}</div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div>
                        {item.city}, {item.state}, {item.country}
                      </div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div>{item.registration}</div>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
        </CModalBody>
      </CModal>
    </CContainer>
  )
}

export default Appusers
