import React, { useState } from 'react'
import { CButton, CCol, CForm, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import URL from 'src/apis/URL'

const Forgot = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)

  const handleForgotPassword = async (e) => {
    e.preventDefault()

    if (!email) {
      toast('Email required!', { type: 'info' })
      return
    }
    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.forgot, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ email_id: email }),
      })

      if (!response.ok) {
        const message = await response.json().then((res) => res.message)
        throw new Error(message)
      }

      setSent(true)
    } catch (error) {
      toast(error.message, { type: 'error' })
    }
  }

  return (
    <CForm noValidate onSubmit={handleForgotPassword}>
      {sent ? (
        <h1>Please Check Your Email!</h1>
      ) : (
        <>
          <h1>Forgot Password</h1>
          <br />
          <CInputGroup className="mb-3">
            <CInputGroupText>
              <CIcon icon={cilUser} />
            </CInputGroupText>
            <CFormInput
              placeholder="Email Address"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </CInputGroup>
        </>
      )}
      <br />

      <CRow className="gap-3">
        {sent ? (
          ''
        ) : (
          <CCol xs={12}>
            <CButton color="info" className="px-4 w-100 text-white" type="submit">
              Submit
            </CButton>
          </CCol>
        )}
        <CCol xs={12} className="text-right">
          <CButton
            color="info"
            className="px-4 w-100"
            variant="outline"
            onClick={() => {
              navigate('/login')
            }}
          >
            Go Back
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  )
}

export default Forgot
