import { cilArrowLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CImage,
  CLink,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSingleAppUser } from 'src/redux/appusers'
import imgPic from 'src/assets/images/profile.png'
import bannerImg from 'src/assets/images/banner.png'

const Showusers = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [activeKey, setActiveKey] = useState(1)
  const { singleAppUser } = useSelector((state) => state.appuser)

  useEffect(() => {
    dispatch(getSingleAppUser(id))
  }, [dispatch, id])

  const goBack = () => {
    window.history.back()
  }

  return (
    <CContainer>
      <div className="d-flex justify-content-end">
        <CButton color="secondary" size="sm" className="text-white" onClick={() => goBack()}>
          <CIcon icon={cilArrowLeft} className="text-white" /> Go Back
        </CButton>
      </div>
      <CRow className="justify-content-center my-2">
        <CCol md={12} lg={12} xl={12}>
          <CCard className="px-4">
            <CCardBody className="p-4">
              <CNav variant="pills" layout="fill" role="tablist">
                <CNavItem role="presentation" className="">
                  <CNavLink
                    active={activeKey === 1}
                    component="button"
                    role="tab"
                    aria-controls="1-tab-pane"
                    aria-selected={activeKey === 1}
                    onClick={() => setActiveKey(1)}
                  >
                    Personal Details
                  </CNavLink>
                </CNavItem>
                <CNavItem role="presentation">
                  <CNavLink
                    active={activeKey === 2}
                    component="button"
                    role="tab"
                    aria-controls="2-tab-pane"
                    aria-selected={activeKey === 2}
                    onClick={() => setActiveKey(2)}
                  >
                    Religion and Family Details
                  </CNavLink>
                </CNavItem>
                <CNavItem role="presentation">
                  <CNavLink
                    active={activeKey === 3}
                    component="button"
                    role="tab"
                    aria-controls="3-tab-pane"
                    aria-selected={activeKey === 3}
                    onClick={() => setActiveKey(3)}
                  >
                    Educational and Professional
                  </CNavLink>
                </CNavItem>
                <CNavItem role="presentation">
                  <CNavLink
                    active={activeKey === 4}
                    component="button"
                    role="tab"
                    aria-controls="4-tab-pane"
                    aria-selected={activeKey === 4}
                    onClick={() => setActiveKey(4)}
                  >
                    Residence and Properties
                  </CNavLink>
                </CNavItem>
                <CNavItem role="presentation">
                  <CNavLink
                    active={activeKey === 5}
                    component="button"
                    role="tab"
                    aria-controls="5-tab-pane"
                    aria-selected={activeKey === 5}
                    onClick={() => setActiveKey(5)}
                  >
                    Preferences Partner
                  </CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent className="my-4">
                <CTabPane role="tabpanel" aria-labelledby="1-tab-pane" visible={activeKey === 1}>
                  <CRow>
                    <CCol sm={3} lg={3} md={3}>
                      <div className="image-size m-2" style={{ height: 250 }}>
                        <CLink href={singleAppUser?.profile_pic} target="blank">
                          <CImage
                            src={singleAppUser?.profile_pic ? singleAppUser?.profile_pic : imgPic}
                            className="image-profile"
                            style={{ height: 250, width: 250 }}
                          />
                        </CLink>
                      </div>
                    </CCol>

                    <CCol sm={3} lg={3} md={3}>
                      <h6 className="py-1">
                        Name:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.name}</text>
                      </h6>
                      <h6 className="py-1">
                        Username:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.username}</text>
                      </h6>
                      <h6 className="py-1">
                        Email:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.email_id}</text>
                      </h6>
                      <h6 className="py-1">
                        Mobile:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.mobile_no}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Martial Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.martial_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        WhatsApp No.:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.whatsapp_no}
                        </text>
                      </h6>
                    </CCol>
                    <CCol sm={3} lg={3} md={3}>
                      <h6 className="py-1">
                        Age:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.age}</text>
                      </h6>
                      <h6 className="py-1">
                        Gender:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.sex}</text>
                      </h6>
                      <h6 className="py-1">
                        Skintone:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.skintone}</text>
                      </h6>
                      <h6 className="py-1">
                        Height:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.height}</text>
                      </h6>
                      <h6 className="py-1">
                        Date of Birth:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.dob}</text>
                      </h6>
                      <h6 className="py-1">
                        Disability:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.any_disability}
                        </text>
                      </h6>
                    </CCol>
                    <CCol sm={3} lg={3} md={3}>
                      <h6 className="py-1">
                        City:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.city}</text>
                      </h6>

                      <h6 className="py-1">
                        State:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.state}</text>
                      </h6>
                      <h6 className="py-1">
                        Country:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.country}</text>
                      </h6>
                      <h6 className="py-1">
                        Registration:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.registration}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Nationality:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.nationality}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Total Income:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.total_income}
                        </text>
                      </h6>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm={4} lg={4} md={4}>
                      <h5 className="text-center m-2">Selfie Photo</h5>
                      <CLink href={singleAppUser?.my_selfie} target="blank">
                        <CImage
                          src={singleAppUser?.my_selfie ? singleAppUser?.my_selfie : bannerImg}
                          style={{
                            width: '100%',
                            height: 300,
                            borderRadius: 20,
                            justifyContent: 'center',
                          }}
                        />
                      </CLink>
                    </CCol>
                    <CCol sm={4} lg={4} md={4}>
                      <h5 className="text-center m-2">Front Id Card</h5>
                      <CLink href={singleAppUser?.front_id_card} target="blank">
                        <CImage
                          src={
                            singleAppUser?.front_id_card ? singleAppUser?.front_id_card : bannerImg
                          }
                          style={{
                            width: '100%',
                            height: 300,
                            borderRadius: 20,
                            justifyContent: 'center',
                          }}
                        />
                      </CLink>
                    </CCol>
                    <CCol sm={4} lg={4} md={4}>
                      <h5 className="text-center m-2">Back Id Card</h5>
                      <CLink href={singleAppUser?.back_id_card} target="blank">
                        <CImage
                          src={
                            singleAppUser?.back_id_card ? singleAppUser?.back_id_card : bannerImg
                          }
                          style={{
                            width: '100%',
                            height: 300,
                            borderRadius: 20,
                            justifyContent: 'center',
                          }}
                        />
                      </CLink>
                    </CCol>
                  </CRow>
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="2-tab-pane" visible={activeKey === 2}>
                  <CRow>
                    <CCol sm={4} lg={4} md={4}>
                      <h6 className="py-1">
                        Religion:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.religion}</text>
                      </h6>
                      <h6 className="py-1">
                        Sect Firqa:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.sect_firqa}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Community:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.community}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Cast:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.cast}</text>
                      </h6>
                    </CCol>
                    <CCol sm={4} lg={4} md={4}>
                      <h6 className="py-1">
                        Father&apos;s Name:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.father_name}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Father&apos;s Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.father_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Father&apos;s Company Name:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.father_company_name}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Father&apos;s Designation:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.father_designation}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Mother&apos;s Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.mother_status}
                        </text>
                      </h6>
                    </CCol>
                    <CCol sm={4} lg={4} md={4}>
                      <h6 className="py-1">
                        Number of Brothers:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.no_of_brother}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Brother&apos;s Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.brother_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Number of Sisters:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.no_of_sister}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Sister&apos;s Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.sister_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Occupation of Brothers/Sisters:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.occu_of_bro_sis}
                        </text>
                      </h6>
                    </CCol>
                  </CRow>
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="3-tab-pane" visible={activeKey === 3}>
                  <CRow>
                    <CCol sm={6} lg={6} md={6}>
                      <h6 className="py-1">
                        Education Level:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.edu_level}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Degree:
                        <text className="text-medium-emphasis px-2">{singleAppUser?.degree}</text>
                      </h6>
                      <h6 className="py-1">
                        Institute:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.institute}
                        </text>
                      </h6>
                    </CCol>

                    <CCol sm={6} lg={6} md={6}>
                      <h6 className="py-1">
                        Education Summary:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.edu_summary}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Working Sector:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.working_sector}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Profession/Job:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.profession_job}
                        </text>
                      </h6>
                    </CCol>
                  </CRow>
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="4-tab-pane" visible={activeKey === 4}>
                  <CRow>
                    <CCol sm={6} lg={6} md={6}>
                      <h6 className="py-1">
                        Residence Living Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.residence_living_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Residence Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.residence_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Residence Size:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.residence_size}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Residence Country:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.residence_country}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Residence State:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.residence_state}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Residence City:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.residence_city}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Residence Area:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.residence_area}
                        </text>
                      </h6>
                    </CCol>

                    <CCol sm={6} lg={6} md={6}>
                      <h6 className="py-1">
                        Hometown Living Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.hometown_living_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Hometown Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.hometown_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Hometown Size:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.hometown_size}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Hometown Country:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.hometown_country}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Hometown State:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.hometown_state}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Hometown City:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.hometown_city}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Hometown Area:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.hometown_area}
                        </text>
                      </h6>
                    </CCol>
                  </CRow>
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="5-tab-pane" visible={activeKey === 5}>
                  <CRow>
                    <CCol sm={6} lg={6} md={6}>
                      <h6 className="py-1">
                        Partner Age Between:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_age_between}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Marital Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_marital_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Nationality:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_nationality}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Religion:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_religion}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Sect/Firqa:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_sect_firqa}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Community:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_community}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Cast:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_cast}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Skintone:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_skintone}
                        </text>
                      </h6>
                    </CCol>

                    <CCol sm={6} lg={6} md={6}>
                      <h6 className="py-1">
                        Partner Any Disability:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_any_disability}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Education Level:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_edu_level}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Degree:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_degree}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Working Sector:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_working_sector}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Profession/Job:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_profession_job}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Residence Status:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_residence_status}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Residence Country:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_residence_country}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Residence State:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_residence_state}
                        </text>
                      </h6>
                      <h6 className="py-1">
                        Partner Residence City:
                        <text className="text-medium-emphasis px-2">
                          {singleAppUser?.partner_residence_city}
                        </text>
                      </h6>
                    </CCol>
                  </CRow>
                </CTabPane>
              </CTabContent>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default Showusers
