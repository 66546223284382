import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getBlockedUsersRequest = () => ({
  type: 'GET_BLOCKED_USERS_REQUEST',
})

export const getBlockedUsersSuccess = (data) => ({
  type: 'GET_BLOCKED_USERS_SUCCESS',
  data,
})

export const getBlockedUsersFailure = (error) => ({
  type: 'GET_BLOCKED_USERS_FAILURE',
  error,
})
export const blockUserRequest = () => ({
  type: 'BLOCK_USER_REQUEST',
})

export const blockUserSuccess = (data) => ({
  type: 'BLOCK_USER_SUCCESS',
  data,
})

export const blockUserFailure = (error) => ({
  type: 'BLOCK_USER_FAILURE',
  error,
})

export const getBlockedUsers = (search, page = 1) => {
  return (dispatch) => {
    dispatch(getBlockedUsersRequest())
    let url = URL.BASE_URL + URL.END_POINT.blockusers

    let obj = {}
    if (page) obj.page = page
    if (search) obj.search = search

    Object.keys(obj).forEach((key, i) => {
      if (i === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getBlockedUsersSuccess(data))
      })
      .catch((error) => {
        dispatch(getBlockedUsersFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const blockUser = (id, data = {}, search, currentPage, status) => {
  return (dispatch) => {
    dispatch(blockUserRequest())
    let url = URL.BASE_URL + URL.END_POINT.reportusers + '/' + id

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(blockUserSuccess(data))
      })
      .catch((error) => {
        dispatch(blockUserFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  blockedUsersData: [],
  loadingBlockedUsersData: false,
  errorBlockedUsersData: null,
}

const blockUsersReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_BLOCKED_USERS_REQUEST':
      return {
        ...state,
        loadingBlockedUsersData: true,
        errorBlockedUsersData: null,
      }
    case 'GET_BLOCKED_USERS_SUCCESS':
      return {
        ...state,
        blockedUsersData: action.data,
        loadingBlockedUsersData: false,
        errorBlockedUsersData: null,
      }
    case 'GET_BLOCKED_USERS_FAILURE':
      return {
        ...state,
        loadingBlockedUsersData: false,
        errorBlockedUsersData: action.error,
      }
    case 'BLOCK_USER_REQUEST':
      return {
        ...state,
        loadingBlockedUsersData: true,
        errorBlockedUsersData: null,
      }
    case 'BLOCK_USER_SUCCESS':
      return {
        ...state,
        loadingBlockedUsersData: false,
        errorBlockedUsersData: null,
      }
    case 'BLOCK_USER_FAILURE':
      return {
        ...state,
        loadingBlockedUsersData: false,
        errorBlockedUsersData: action.error,
      }
    default:
      return state
  }
}

export default blockUsersReducer
