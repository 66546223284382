import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getNotificationDataRequest = () => ({
  type: 'GET_NOTIFICATION_DATA_REQUEST',
})

export const getNotificationDataSuccess = (data) => ({
  type: 'GET_NOTIFICATION_DATA_SUCCESS',
  data,
})

export const getNotificationDataFailure = (error) => ({
  type: 'GET_NOTIFICATION_DATA_FAILURE',
  error,
})

export const sendNotificationRequest = () => ({
  type: 'SEND_NOTIFICATION_REQUEST',
})

export const sendNotificationSuccess = (data) => ({
  type: 'SEND_NOTIFICATION_SUCCESS',
  data,
})

export const sendNotificationFailure = (error) => ({
  type: 'SEND_NOTIFICATION_FAILURE',
  error,
})

export const deleteNotificationRequest = () => ({
  type: 'DELETE_NOTIFICATION_REQUEST',
})

export const deleteNotificationSuccess = (id) => ({
  type: 'DELETE_NOTIFICATION_SUCCESS',
  id,
})

export const deleteNotificationFailure = (error) => ({
  type: 'DELETE_NOTIFICATION_FAILURE',
  error,
})

export const getNotificationData = (userid) => {
  return (dispatch) => {
    dispatch(getNotificationDataRequest())

    fetch(URL.BASE_URL + URL.END_POINT.notification + userid, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getNotificationDataSuccess(data))
      })
      .catch((error) => {
        dispatch(getNotificationDataFailure(error.message))
        // toast(error.message, { type: 'error' })
      })
  }
}

export const sendNotification = (body) => {
  return (dispatch) => {
    dispatch(sendNotificationRequest())

    fetch(URL.BASE_URL + URL.END_POINT.sendnotification, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(sendNotificationSuccess(data))
        toast(data.message, { type: 'success' })
      })
      .catch((error) => {
        dispatch(sendNotificationFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const sendNotificationToAll = (body) => {
  return (dispatch) => {
    dispatch(sendNotificationRequest())

    fetch(URL.BASE_URL + URL.END_POINT.notificationsend, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(sendNotificationSuccess(data))
        toast(data.message, { type: 'success' })
      })
      .catch((error) => {
        dispatch(sendNotificationFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteNotification = (id) => {
  return (dispatch) => {
    dispatch(deleteNotificationRequest())

    fetch(URL.BASE_URL + URL.END_POINT.notification + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(deleteNotificationSuccess(id))
        toast('Notification deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteNotificationFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  notificationData: {},
  loadingNotificationData: false,
  errorNotificationData: null,
}

export const getNotificationReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_NOTIFICATION_DATA_REQUEST':
      return {
        ...state,
        loadingNotificationData: true,
        errorNotificationData: null,
      }
    case 'GET_NOTIFICATION_DATA_SUCCESS':
      return {
        ...state,
        notificationData: action.data,
        loadingNotificationData: false,
        errorNotificationData: null,
      }
    case 'GET_NOTIFICATION_DATA_FAILURE':
      return {
        ...state,
        loadingNotificationData: false,
        errorNotificationData: action.error,
      }
    default:
      return state
  }
}

const initialSendAppState = {
  sendingNotification: false,
  errorSendingNotification: null,
}

export const sendNotificationReducer = (state = initialSendAppState, action) => {
  switch (action.type) {
    case 'SEND_NOTIFICATION_REQUEST':
      return {
        ...state,
        sendingNotification: true,
        errorSendingNotification: null,
      }
    case 'SEND_NOTIFICATION_SUCCESS':
      return {
        ...state,
        sendingNotification: false,
        errorSendingNotification: null,
      }
    case 'SEND_NOTIFICATION_FAILURE':
      return {
        ...state,
        sendingNotification: false,
        errorSendingNotification: action.error,
      }
    default:
      return state
  }
}

const initialDeleteAppState = {
  deletingNotification: false,
  errorDeletingNotification: null,
}

export const deleteNotificationReducer = (state = initialDeleteAppState, action) => {
  switch (action.type) {
    case 'DELETE_NOTIFICATION_REQUEST':
      return {
        ...state,
        deletingNotification: true,
        errorDeletingNotification: null,
      }
    case 'DELETE_NOTIFICATION_SUCCESS':
      return {
        ...state,
        deletingNotification: false,
        errorDeletingNotification: null,
      }
    case 'DELETE_NOTIFICATION_FAILURE':
      return {
        ...state,
        deletingNotification: false,
        errorDeletingNotification: action.error,
      }
    default:
      return state
  }
}
