import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CInputGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import CIcon from '@coreui/icons-react'
import { cilBell, cilSend, cilTrash, cilUser, cilUserFemale } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getAppUsers } from 'src/redux/appusers'
import {
  deleteNotification,
  getNotificationData,
  sendNotification,
  sendNotificationToAll,
} from 'src/redux/notification'
import Pagination from 'react-js-pagination'

const Notification = () => {
  const [Loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 20
  const [search, setSearch] = useState('')
  const [gender, setGender] = useState('Male')
  const [status, setStatus] = useState('')
  const [visibleLg, setVisibleLg] = useState(false)
  const [notification, setNotification] = useState([])
  const [setselectUser, setSetselectUser] = useState(true)
  const [select, setSelect] = useState({ label: '', value: '' })
  const [formData, setFormData] = useState({
    title: '',
    body: '',
  })

  const dispatch = useDispatch()
  const { appUsers } = useSelector((state) => state.appusers)
  const { notificationData, errorNotificationData, loadingNotificationData } = useSelector(
    (state) => state.getnotification,
  )

  console.log(appUsers)

  useEffect(() => {
    dispatch(getAppUsers(gender, currentPage, status, search))
  }, [dispatch, gender, currentPage, status, search])

  // const filteredAppUsers = appUsers?.app_users?.filter((item) => {
  //   return (
  //     item.username.toLowerCase().includes(search.toLowerCase()) ||
  //     item.email_id.toLowerCase().includes(search.toLowerCase()) ||
  //     item.mobile_no.toLowerCase().includes(search.toLowerCase())
  //   )
  // })

  // const indexOfLastItem = currentPage * itemsPerPage
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage
  // const currentItems = filteredAppUsers?.slice(indexOfFirstItem, indexOfLastItem)

  // const totalItems = filteredAppUsers?.length || 0
  // const totalPages = Math.ceil(totalItems / itemsPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleSend = async () => {
    if (!formData.title || !formData.body) {
      toast('Title and Message required!', { type: 'info' })
    } else {
      try {
        if (setselectUser) {
          const body = {
            title: formData.title,
            body: formData.body,
            data: {},
          }
          dispatch(sendNotificationToAll(body))
        } else {
          const body = {
            title: formData.title,
            body: formData.body,
            token: select.value,
            data: {},
          }
          dispatch(sendNotification(body))
        }
        setFormData({
          title: '',
          body: '',
        })
        setSetselectUser(true)
      } catch (error) {
        toast(error.message, { type: 'error' })
      }
    }
  }

  useEffect(() => {
    if (errorNotificationData) {
      setNotification([])
    } else {
      setNotification(notificationData?.notification)
    }
  }, [dispatch, notificationData, errorNotificationData, loadingNotificationData])

  const getNotification = async (user_code) => {
    dispatch(getNotificationData(user_code))
    setVisibleLg(!visibleLg)
  }

  async function handleDelete(id, user_code) {
    setLoading(true)
    try {
      await dispatch(deleteNotification(id))
    } catch (error) {
      console.log(error)
    } finally {
      await dispatch(getNotificationData(user_code))
      setLoading(false)
    }
  }

  return (
    <div className="bg-light d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={7}>
            <CRow>
              <CCol sm={12} md={6} lg={9} className="mb-2">
                <CFormInput
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="sm"
                />
              </CCol>
              <CCol sm={12} md={6} lg={3} className="mb-2">
                <CButtonGroup className="w-100">
                  {['Male', 'Female'].map((value) => (
                    <CButton
                      color="outline-primary"
                      key={value}
                      active={value === gender}
                      size="sm"
                      onClick={() => {
                        setGender(value)
                        setCurrentPage(1)
                      }}
                    >
                      <CIcon icon={value === 'Female' ? cilUserFemale : cilUser} /> {value}
                    </CButton>
                  ))}
                </CButtonGroup>
              </CCol>
            </CRow>
            <PerfectScrollbar style={{ height: '80vh' }}>
              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell></CTableHeaderCell>
                    <CTableHeaderCell className="text-center">
                      <CFormCheck
                        id="flexCheckChecked"
                        checked={setselectUser}
                        onChange={() => setSetselectUser(!setselectUser)}
                      />
                    </CTableHeaderCell>
                    <CTableHeaderCell>Username</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Mobile</CTableHeaderCell>
                    <CTableHeaderCell>Email Address</CTableHeaderCell>
                    <CTableHeaderCell>View</CTableHeaderCell>
                    <CTableHeaderCell>Send</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {appUsers?.app_users?.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index}>
                      <CTableDataCell>{index + 1})</CTableDataCell>
                      <CTableDataCell>
                        <div className="image-size">
                          <CAvatar size="md" src={item.my_selfie} className="image-profile" />
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{item.name}</div>
                        <div className="small text-medium-emphasis">
                          <strong>{item.username} </strong>
                          {item.sex === 'Female' ? (
                            <CIcon icon={cilUserFemale} />
                          ) : (
                            <CIcon icon={cilUser} />
                          )}{' '}
                          {item.sex}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <div className="clearfix">
                          <small className="text-medium-emphasis">{item.mobile_no}</small>
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <small className="text-medium-emphasis">{item.email_id}</small>
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton
                          color="info"
                          size="sm"
                          onClick={() => getNotification(item.user_code)}
                        >
                          <CIcon icon={cilBell} className="text-white" />
                        </CButton>
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton
                          color="success"
                          size="sm"
                          onClick={() => {
                            setSelect({
                              label: item.name + ' - ' + item.username,
                              value: item.fcm_token,
                            })
                            setSetselectUser(false)
                          }}
                        >
                          <CIcon icon={cilSend} className="text-white" />
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </PerfectScrollbar>
            <ul className="pagination justify-content-center">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={appUsers?.total || 0}
                hideFirstLastPages={false}
                onChange={handlePageChange}
                pageRangeDisplayed={5}
                nextPageText="Next"
                prevPageText="Previous"
                lastPageText="Last"
                firstPageText="1st"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="active"
                activeLinkClass="active"
                hideDisabled={false}
              />
            </ul>
          </CCol>
          <CCol md={9} lg={7} xl={5}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm>
                  <h6 className="mb-3">Send Notification</h6>
                  <CInputGroup className="mb-3">
                    {setselectUser ? (
                      <CFormSwitch
                        defaultChecked
                        value={setselectUser}
                        label="Send to all users"
                        id="formSwitchCheckChecked"
                        onChange={() => setSetselectUser(!setselectUser)}
                        disabled
                      />
                    ) : (
                      <CFormInput disabled value={select.label} />
                    )}
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Enter Title"
                      autoComplete="Title"
                      value={formData.title}
                      onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormTextarea
                      placeholder="Message"
                      autoComplete="Message"
                      value={formData.body}
                      onChange={(e) => setFormData({ ...formData, body: e.target.value })}
                      rows={7}
                    />
                  </CInputGroup>

                  <div className="d-grid">
                    <CButton color="success" onClick={handleSend}>
                      Send
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CModal
          size="lg"
          visible={visibleLg}
          onClose={() => setVisibleLg(false)}
          aria-labelledby="OptionalSizesExample2"
        >
          <CModalHeader>
            <CModalTitle id="OptionalSizesExample2">View Notification</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CCol md={12} lg={12} xl={12}>
              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell className="text-center">Sr.</CTableHeaderCell>
                    <CTableHeaderCell>Title</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Message</CTableHeaderCell>
                    <CTableHeaderCell>Screen</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Delete</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {notification?.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index}>
                      <CTableDataCell className="text-center">
                        <div>{index + 1}) </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{item.title}</div>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <div className="clearfix">
                          <small className="text-medium-emphasis">{item.body}</small>
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <small className="text-medium-emphasis">{item?.data?.screen}</small>
                        </div>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <CButton
                          color="danger"
                          size="sm"
                          onClick={() => handleDelete(item.id, item.user_code)}
                        >
                          <CIcon icon={cilTrash} className="text-white" />
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CCol>
          </CModalBody>
        </CModal>
      </CContainer>
    </div>
  )
}

export default Notification
