import React from 'react'
import { CCard, CCardBody, CCardGroup, CCardImage, CCol, CContainer, CRow } from '@coreui/react'
import { Outlet } from 'react-router-dom'
import ReactImg from 'src/assets/images/banner.png'

const AuthLayout = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4 h-auto">
                <CCardBody>
                  <Outlet />
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-white" style={{ height: '350px' }}>
                <CCardImage src={ReactImg} style={{ height: '350px' }} />
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default AuthLayout
