import React, { useEffect, useState } from 'react'
import {
  CButton,
  CButtonGroup,
  CCloseButton,
  CCol,
  CContainer,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilXCircle } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Pagination from 'react-js-pagination'
import { deleteReportUsers, getReportUsers, blockUser } from 'src/redux/reportusers'
import { DateTimeConverter } from 'src/utils/DateTimeConverter'
import { AppSpinner } from 'src/components'
import { useTableSelection } from 'src/utils/useTableSelection'

const ReportUsers = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const itemsPerPage = 20
  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()

  const { reportUsersData, isLoading } = useSelector((state) => {
    return {
      reportUsersData: state.reportusers,
      isLoading: state?.reportusers?.loadingReportUsersData,
    }
  })

  console.log(reportUsersData, 'reportUsersData')

  const { selectedItems, toggleItemSelection, toggleSelectAll, isAllSelected } = useTableSelection(
    reportUsersData?.reportUsersData?.reportusers,
  )

  useEffect(() => {
    dispatch(getReportUsers(search, currentPage, status))
  }, [search, currentPage, dispatch, loading, status])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  async function handleDelete(id) {
    setLoading(true)
    try {
      await dispatch(deleteReportUsers(id))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleBlock = async (id) => {
    setLoading(true)
    try {
      await dispatch(blockUser(id, {}, search, currentPage, status))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <CContainer>
      <CRow>
        <CCol sm={12} md={6} lg={8} className="mb-2">
          <CFormInput
            type="text"
            placeholder="Search..."
            size="sm"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </CCol>
        <CCol sm={11} md={5} lg={3} className="mb-2">
          <CFormSelect
            size="sm"
            color="info"
            options={[
              { label: 'Status', value: '', disabled: true },
              { label: 'Blocked', value: 'true' },
              { label: 'Unblocked', value: 'false' },
            ]}
            value={status}
            onChange={(event) => setStatus(event.target.value)}
          />
        </CCol>
        <CCol sm={1} md={1} lg={1} className="mb-2">
          <CCloseButton
            onClick={() => {
              setCurrentPage(1)
              setStatus('')
              setSearch('')
            }}
          />
        </CCol>
        {isLoading ? (
          <CContainer className="d-flex justify-content-center align-items-center">
            <AppSpinner />
          </CContainer>
        ) : (
          <>
            <CRow className="justify-content-center">
              <CCol md={12} lg={12} xl={12}>
                <PerfectScrollbar style={{ height: '100%' }}>
                  <CTable align="middle" className="mb-2 border" hover responsive>
                    <CTableHead color="light">
                      <CTableRow>
                        <CTableHeaderCell>
                          <CFormCheck
                            checked={isAllSelected}
                            onChange={toggleSelectAll}
                            color="primary"
                          />
                        </CTableHeaderCell>
                        <CTableHeaderCell>Title</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Message</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Sender Name</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">
                          Receiver Name (Block)
                        </CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Reported Date</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {reportUsersData?.reportUsersData?.reportusers?.map((item, index) => (
                        <CTableRow v-for="item in tableItems" key={index}>
                          {/* <CTableDataCell className="text-center">
                        <div className="clearfix">
                          <small className="text-medium-emphasis">{index + 1}) </small>
                        </div>
                      </CTableDataCell> */}
                          <CTableDataCell>
                            <CFormCheck
                              checked={selectedItems.includes(item.id)}
                              onChange={() => toggleItemSelection(item.id)}
                              color="primary"
                            />
                          </CTableDataCell>
                          <CTableDataCell>
                            <div className="clearfix">
                              <strong>{item.title}</strong>
                            </div>
                          </CTableDataCell>

                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="">{item.message}</h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="">{item.sender_name}</h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="">{item.receiver_name}</h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="">{DateTimeConverter(item.createdAt)}</h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="">{!item?.status ? 'Unblock' : 'blocked'}</h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <CButtonGroup role="group" aria-label="Basic mixed styles example">
                              <CButton
                                color="success"
                                size="sm"
                                onClick={() => handleDelete(item.id)}
                              >
                                <CIcon icon={cilXCircle} className="text-white" />
                              </CButton>
                              <CButton
                                color={item?.status ? 'warning' : 'danger'}
                                size="sm"
                                onClick={() => handleBlock(item.id)}
                              >
                                {item?.status ? 'Unblock' : 'Block'}
                              </CButton>
                            </CButtonGroup>
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                </PerfectScrollbar>
              </CCol>
            </CRow>
            <ul className="pagination justify-content-center">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={reportUsersData?.reportUsersData?.total || 0}
                hideFirstLastPages={false}
                onChange={handlePageChange}
                pageRangeDisplayed={5}
                nextPageText="Next"
                prevPageText="Previous"
                lastPageText="Last"
                firstPageText="1st"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="active"
                activeLinkClass="active"
                hideDisabled={false}
              />
            </ul>
          </>
        )}
      </CRow>
    </CContainer>
  )
}

export default ReportUsers
