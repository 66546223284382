import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getCountryRequest = () => ({
  type: 'GET_COUNTRY_REQUEST',
})

export const getCountrySuccess = (countryData) => ({
  type: 'GET_COUNTRY_SUCCESS',
  countryData,
})

export const getCountryFailure = (error) => ({
  type: 'GET_COUNTRY_FAILURE',
  error,
})

export const getCountry = () => {
  return (dispatch) => {
    dispatch(getCountryRequest())

    fetch(URL.BASE_URL + URL.END_POINT.countries, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((countryData) => {
        dispatch(getCountrySuccess(countryData))
      })
      .catch((error) => {
        dispatch(getCountryFailure(error.message))
        // toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  countryData: null,
  loadingCountry: false,
  errorCountry: null,
}

export const countryReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_COUNTRY_REQUEST':
      return {
        ...state,
        loadingCountry: true,
        errorCountry: null,
      }
    case 'GET_COUNTRY_SUCCESS':
      return {
        ...state,
        countryData: action.countryData,
        loadingCountry: false,
        errorCountry: null,
      }
    case 'GET_COUNTRY_FAILURE':
      return {
        ...state,
        loadingCountry: false,
        errorCountry: action.error,
      }
    default:
      return state
  }
}
