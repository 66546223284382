import { applyMiddleware, combineReducers, createStore } from 'redux'
import { sidebarShowReducer, sidebarUnfoldableReducer } from './redux/sidebar'
import { composeWithDevTools } from 'redux-devtools-extension'
import { thunk } from 'redux-thunk'
import loginReducer from './redux/login'
import { appReducer, appUserReducer } from './redux/appusers'
import adminReducer from './redux/adminusers'
import signupReducer from './redux/signup'
import reportReducer from './redux/reportdata'
import paymentHistoryReducer from './redux/paymenthistory'
import {
  addmasterReducer,
  deletemasterReducer,
  getmasterReducer,
  updatemasterReducer,
} from './redux/masterdata'
import {
  deleteNotificationReducer,
  getNotificationReducer,
  sendNotificationReducer,
} from './redux/notification'
import { packagesReducer } from './redux/packages'
import { bankDetailsReducer } from './redux/bankdetails'
import { registrationReducer, signupAppReducer, verifyOtpReducer } from './redux/registration'
import subscriptionReducer from './redux/subscription'
import { adminProfileReducer } from './redux/adminprofile'
import { dashboardReducer } from './redux/dashboard'
import { countryReducer } from './redux/country'
import { statesReducer } from './redux/states'
import { citiesReducer } from './redux/cities'
import reportUsersReducer from './redux/reportusers'
import blockUsersReducer from './redux/blockusers'
import { specialUsersReducer } from './redux/specialuser'

const rootReducer = combineReducers({
  sidebarShow: sidebarShowReducer,
  sidebarUnfoldable: sidebarUnfoldableReducer,
  country: countryReducer,
  states: statesReducer,
  cities: citiesReducer,
  login: loginReducer,
  signup: signupReducer,
  appusers: appReducer,
  appuser: appUserReducer,
  adminusers: adminReducer,
  reportdata: reportReducer,
  reportusers: reportUsersReducer,
  blockusers: blockUsersReducer,
  packages: packagesReducer,
  bankdetails: bankDetailsReducer,
  getmasterdata: getmasterReducer,
  addmasterdata: addmasterReducer,
  updatemasterdata: updatemasterReducer,
  deletemasterdata: deletemasterReducer,
  paymenthistory: paymentHistoryReducer,
  getnotification: getNotificationReducer,
  sendnotification: sendNotificationReducer,
  deletenotification: deleteNotificationReducer,
  signupapp: signupAppReducer,
  verifyotp: verifyOtpReducer,
  registration: registrationReducer,
  subscription: subscriptionReducer,
  adminprofile: adminProfileReducer,
  admindashboard: dashboardReducer,
  specialUsers: specialUsersReducer,
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
export default store
