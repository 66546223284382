import React from 'react'
import { CContainer } from '@coreui/react'

const PrivacyPolicy = () => {
  return (
    <CContainer>
      <h2>Privacy Policy</h2>

      <p>
        Nikkah built the Nikkah app as a Free app. This SERVICE is provided by Nikkah at no cost and
        is intended for use as is. This page is used to inform visitors regarding my policies with
        the collection, use, and disclosure of Personal Information if anyone decided to use my
        Service. If you choose to use my Service, then you agree to the collection and use of
        information in relation to this policy. The Personal Information that I collect is used for
        providing and improving the Service. I will not use or share your information with anyone
        except as described in this Privacy Policy. The terms used in this Privacy Policy have the
        same meanings as in our Terms and Conditions, which are accessible at Nikkah unless
        otherwise defined in this Privacy Policy.
      </p>

      <h5>Information Collection and Use</h5>

      <p>
        For a better experience, while using our Service, I may require you to provide us with
        certain personally identifiable information, including but not limited to Name,IP,email
        Address,Phone number. The information that I request will be retained on your device and is
        not collected by me in any way. The app does use third-party services that may collect
        information used to identify you. Link to the privacy policy of third-party service
        providers used by the app:
        <br />
        <br />
        <ul>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.nikkah"
              rel="noreferrer"
              target="_blank"
            >
              Google Play Services
            </a>
          </li>
        </ul>
      </p>

      <h5>Log Data</h5>

      <p>
        I want to inform you that whenever you use my Service, in the case of an error in the app I
        collect data and information (through third-party products) on your phone called Log Data.
        This Log Data may include information such as your device Internet Protocol (“IP”) address,
        device name, operating system version, the configuration of the app when utilizing my
        Service, the time and date of your use of the Service, and other statistics.
      </p>

      <h5>Service Providers</h5>

      <p>I may employ third-party companies and individuals due to the following reasons:</p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>

      <p>
        I want to inform users of this Service that these third parties have access to their
        Personal Information. The reason is to perform the tasks assigned to them on our behalf.
        However, they are obligated not to disclose or use the information for any other purpose.
      </p>

      <h5>Security</h5>

      <p>
        I value your trust in providing us your Personal Information, thus we are striving to use
        commercially acceptable means of protecting it. But remember that no method of transmission
        over the internet, or method of electronic storage is 100% secure and reliable, and I cannot
        guarantee its absolute security.
      </p>

      <h5>Links to Other Sites</h5>

      <p>
        This Service may contain links to other sites. If you click on a third-party link, you will
        be directed to that site. Note that these external sites are not operated by me. Therefore,
        I strongly advise you to review the Privacy Policy of these websites. I have no control over
        and assume no responsibility for the content, privacy policies, or practices of any
        third-party sites or services.
      </p>

      <h5>Children’s Privacy</h5>

      <p>
        These Services do not address anyone under the age of 18. I do not knowingly collect
        personally identifiable information from children under 18 years of age. In the case I
        discover that a child under 18 has provided me with personal information, I immediately
        delete this from our servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact me so that I will be able to
        do the necessary actions.
      </p>

      <h5>Changes to This Privacy Policy</h5>

      <p>
        I may update our Privacy Policy from time to time. Thus, you are advised to review this page
        periodically for any changes. I will notify you of any changes by posting the new Privacy
        Policy on this page. This policy is effective as of 2024-01-22
      </p>

      <h5>Contact Us</h5>

      <p>
        If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact
        me at <a href="mailto:1nikkah@gmail.com">1nikkah@gmail.com</a>.
      </p>
    </CContainer>
  )
}

export default PrivacyPolicy
