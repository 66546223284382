import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getReportUsersRequest = () => ({
  type: 'GET_REPORT_USERS_REQUEST',
})

export const getReportUsersSuccess = (data) => ({
  type: 'GET_REPORT_USERS_SUCCESS',
  data,
})

export const getReportUsersFailure = (error) => ({
  type: 'GET_REPORT_USERS_FAILURE',
  error,
})

export const deleteReportUsersRequest = () => ({
  type: 'DELETE_REPORT_USERS_REQUEST',
})

export const deleteReportUsersSuccess = (data) => ({
  type: 'DELETE_REPORT_USERS_SUCCESS',
  data,
})

export const deleteReportUsersFailure = (error) => ({
  type: 'DELETE_REPORT_USERS_FAILURE',
  error,
})

export const getReportUsers = (search, page = 1, status) => {
  return (dispatch) => {
    dispatch(getReportUsersRequest())
    let url = URL.BASE_URL + URL.END_POINT.reportusers

    let obj = {}
    if (page) obj.page = page
    if (search) obj.search = search
    if (status) obj.status = status

    Object.keys(obj).forEach((key, i) => {
      if (i === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getReportUsersSuccess(data))
      })
      .catch((error) => {
        dispatch(getReportUsersFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteReportUsers = (id) => {
  return (dispatch) => {
    dispatch(deleteReportUsersRequest())
    let url = URL.BASE_URL + URL.END_POINT.reportusers + '/' + id

    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(deleteReportUsersSuccess(data))
      })
      .catch((error) => {
        dispatch(deleteReportUsersFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const blockUser = (id, data = {}) => {
  return (dispatch) => {
    dispatch(getReportUsersRequest())
    let url = URL.BASE_URL + URL.END_POINT.reportusers + '/' + id

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {})
      .catch((error) => {
        dispatch(getReportUsersFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  reportUsersData: [],
  loadingReportUsersData: false,
  errorReportUsersData: null,
}

const reportUsersReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_REPORT_USERS_REQUEST':
      return {
        ...state,
        loadingReportUsersData: true,
        errorReportUsersData: null,
      }
    case 'GET_REPORT_USERS_SUCCESS':
      return {
        ...state,
        reportUsersData: action.data,
        loadingReportUsersData: false,
        errorReportUsersData: null,
      }
    case 'GET_REPORT_USERS_FAILURE':
      return {
        ...state,
        loadingReportUsersData: false,
        errorReportUsersData: action.error,
      }
    case 'DELETE_REPORT_USERS_REQUEST':
      return {
        ...state,
        loadingReportUsersData: true,
        errorReportUsersData: null,
      }
    case 'DELETE_REPORT_USERS_SUCCESS':
      return {
        ...state,
        loadingReportUsersData: false,
        errorReportUsersData: null,
      }
    case 'DELETE_REPORT_USERS_FAILURE':
      return {
        ...state,
        loadingReportUsersData: false,
        errorReportUsersData: action.error,
      }
    default:
      return state
  }
}

export default reportUsersReducer
