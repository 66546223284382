import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getAdminProfileRequest = () => ({
  type: 'GET_ADMIN_PROFILE_REQUEST',
})

export const getAdminProfileSuccess = (profile) => ({
  type: 'GET_ADMIN_PROFILE_SUCCESS',
  profile,
})

export const getAdminProfileFailure = (error) => ({
  type: 'GET_ADMIN_PROFILE_FAILURE',
  error,
})

export const getAdminProfile = () => {
  return (dispatch) => {
    dispatch(getAdminProfileRequest())

    fetch(URL.BASE_URL + URL.END_POINT.adminprofile, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((profile) => {
        dispatch(getAdminProfileSuccess(profile))
      })
      .catch((error) => {
        dispatch(getAdminProfileFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  adminProfile: null,
  loadingAdminProfile: false,
  errorAdminProfile: null,
}

export const adminProfileReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_ADMIN_PROFILE_REQUEST':
      return {
        ...state,
        loadingAdminProfile: true,
        errorAdminProfile: null,
      }
    case 'GET_ADMIN_PROFILE_SUCCESS':
      return {
        ...state,
        adminProfile: action.profile.profile,
        loadingAdminProfile: false,
        errorAdminProfile: null,
      }
    case 'GET_ADMIN_PROFILE_FAILURE':
      return {
        ...state,
        loadingAdminProfile: false,
        errorAdminProfile: action.error,
      }
    default:
      return state
  }
}
