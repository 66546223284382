export const validatePassword = (new_password, confirm_password) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,11}$/

  if (!new_password) return 'Password required!'
  if (!confirm_password) return 'Confirm Password required!'
  if (new_password !== confirm_password) return 'Passwords must match!'
  if (!passwordRegex.test(new_password)) {
    return 'Password must be 8-11 characters long, with at least one uppercase letter, one lowercase letter, and one number.'
  }

  return null
}
