/* eslint-disable react/prop-types */
import React from 'react'
import { CInputGroup, CInputGroupText, CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked } from '@coreui/icons'

const PasswordInput = ({ placeholder, value, name, onChangeHandle }) => {
  return (
    <CInputGroup className="mb-4">
      <CInputGroupText>
        <CIcon icon={cilLockLocked} />
      </CInputGroupText>
      <CFormInput
        type="password"
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChangeHandle}
      />
    </CInputGroup>
  )
}

export default PasswordInput
