import React, { useEffect } from 'react'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilAccountLogout, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminProfile } from 'src/redux/adminprofile'

const AppHeaderDropdown = () => {
  const dispatch = useDispatch()
  const { adminProfile } = useSelector((state) => state.adminprofile)

  useEffect(() => {
    dispatch(getAdminProfile())
  }, [dispatch])

  const handleLogout = () => {
    window.localStorage.clear()
    window.location.reload()
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="d-flex py-0" caret={false}>
        <h6 className="m-auto px-2">{adminProfile?.name}</h6>
        <div className="image-size">
          <CAvatar size="md" src={adminProfile?.profile} className="image-profile" />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem href="#" onClick={handleLogout}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
