import React, { useState } from 'react'
import { CButton, CCol, CForm, CRow } from '@coreui/react'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import URL from 'src/apis/URL'
import { validatePassword } from 'src/utils/validatePassword'
import PasswordInput from 'src/components/PasswordInput'

const ResetPassword = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [data, setData] = useState({
    new_password: '',
    confirm_password: '',
  })

  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  const onChangeHandle = (event) => {
    const { name, value } = event.target
    setData({ ...data, [name]: value })
  }

  const handleLogin = async (e) => {
    e.preventDefault()

    const validationError = validatePassword(data.new_password, data.confirm_password)
    if (validationError) {
      toast(validationError, { type: 'info' })
      return
    }

    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.reset, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reset_token: token,
          new_password: data.new_password,
        }),
      })

      if (!response.ok) {
        const message = await response.json().then((res) => res.message)
        throw new Error(message)
      }

      toast('Password reset successful!', { type: 'success' })
      navigate('/login')
    } catch (error) {
      toast(error.message, { type: 'error' })
    }
  }

  return (
    <CForm noValidate onSubmit={handleLogin}>
      <h1>Reset Password</h1>
      <p className="text-medium-emphasis">Create New Password</p>
      <PasswordInput
        placeholder="New Password"
        value={data.new_password}
        name="new_password"
        onChangeHandle={onChangeHandle}
      />
      <PasswordInput
        placeholder="Confirm Password"
        value={data.confirm_password}
        name="confirm_password"
        onChangeHandle={onChangeHandle}
      />
      <CRow>
        <CCol xs={12}>
          <CButton color="info" className="px-4 w-100 text-white" type="submit">
            Save Password
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  )
}

export default ResetPassword
