import React, { useEffect, useState } from 'react'
import {
  cilAddressBook,
  cilBellExclamation,
  cilFingerprint,
  cilGroup,
  cilMobile,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
} from '@coreui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppSpinner } from 'src/components'
import URL from 'src/apis/URL'
import { toast } from 'react-toastify'

const EditSpecialUser = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { userId } = location.state || {}
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState({
    name: '',
    phone_number: '',
    gender: 'male',
    status: '',
    city: '',
    age: '',
  })

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(URL.BASE_URL + URL.END_POINT.getSpecialUsers + '/' + userId, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            USERID: localStorage.getItem('USERID'),
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })

        const data = await response.json()
        const user = await data.data

        setUser({
          name: user.name || '',
          phone_number: user.phone_number || '',
          gender: user.gender || 'male',
          status: user.status || '',
          city: user.city || '',
          age: user.age || '',
        })
        setIsLoading(false)
      } catch (error) {
        toast(error.message, { type: 'error' })
      }
    }
    fetchUser()
  }, [userId])

  const handleChange = (e) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.updateSpecialUser + userId, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          USERID: localStorage.getItem('USERID'),
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(user),
      })

      if (!response.ok) {
        const message = await response.json().then((res) => res.message)
        throw new Error(message)
      }
      toast('Special User updated successfully! ', { type: 'success' })
      navigate('/users/special')
    } catch (error) {
      toast(error.message, { type: 'error' })
    }
  }

  return (
    <>
      {' '}
      {isLoading ? (
        <CContainer className="d-flex justify-content-center align-items-center">
          <AppSpinner />
        </CContainer>
      ) : (
        <CContainer>
          <CCard className="mx-4">
            <CCardBody className="p-4">
              <CForm>
                <h1 className="mb-3">Edit Special User</h1>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Name"
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilMobile} />
                  </CInputGroupText>
                  <CFormInput
                    name="phone_number"
                    placeholder="Mobile"
                    value={user.phone_number}
                    onChange={handleChange}
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilGroup} />
                  </CInputGroupText>
                  <CFormSelect name="gender" value={user.gender} onChange={handleChange}>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </CFormSelect>
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilBellExclamation} />
                  </CInputGroupText>
                  <CFormInput
                    name="status"
                    placeholder="Status"
                    value={user.status}
                    onChange={handleChange}
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilAddressBook} />
                  </CInputGroupText>
                  <CFormInput
                    name="city"
                    placeholder="City"
                    value={user.city}
                    onChange={handleChange}
                  />
                </CInputGroup>
                <CInputGroup className="mb-4">
                  <CInputGroupText>
                    <CIcon icon={cilFingerprint} />
                  </CInputGroupText>
                  <CFormInput
                    name="age"
                    placeholder="Age"
                    value={user.age}
                    onChange={handleChange}
                  />
                </CInputGroup>
                <div className="d-grid mb-3">
                  <CButton color="success" onClick={handleSubmit}>
                    Edit
                  </CButton>
                </div>
              </CForm>
            </CCardBody>
          </CCard>
        </CContainer>
      )}
    </>
  )
}

export default EditSpecialUser
