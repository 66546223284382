import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getCitiesRequest = () => ({
  type: 'GET_CITIES_REQUEST',
})

export const getCitiesSuccess = (citiesData) => ({
  type: 'GET_CITIES_SUCCESS',
  citiesData,
})

export const getCitiesFailure = (error) => ({
  type: 'GET_CITIES_FAILURE',
  error,
})

export const getCities = (body) => {
  return (dispatch) => {
    dispatch(getCitiesRequest())

    fetch(URL.BASE_URL + URL.END_POINT.cities + body, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((citiesData) => {
        dispatch(getCitiesSuccess(citiesData))
      })
      .catch((error) => {
        dispatch(getCitiesFailure(error.message))
        // toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  citiesData: null,
  loadingCities: false,
  errorCities: null,
}

export const citiesReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_CITIES_REQUEST':
      return {
        ...state,
        loadingCities: true,
        errorCities: null,
      }
    case 'GET_CITIES_SUCCESS':
      return {
        ...state,
        citiesData: action.citiesData,
        loadingCities: false,
        errorCities: null,
      }
    case 'GET_CITIES_FAILURE':
      return {
        ...state,
        loadingCities: false,
        errorCities: action.error,
      }
    default:
      return state
  }
}
