import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getDashboardRequest = () => ({
  type: 'GET_DASHBOARD_REQUEST',
})

export const getDashboardSuccess = (dashboardData) => ({
  type: 'GET_DASHBOARD_SUCCESS',
  dashboardData,
})

export const getDashboardFailure = (error) => ({
  type: 'GET_DASHBOARD_FAILURE',
  error,
})

export const getDashboard = () => {
  return (dispatch) => {
    dispatch(getDashboardRequest())

    fetch(URL.BASE_URL + URL.END_POINT.dashboard, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((dashboardData) => {
        dispatch(getDashboardSuccess(dashboardData))
      })
      .catch((error) => {
        dispatch(getDashboardFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  progressGender: null,
  progressProposal: null,
  progressRequest: null,
  todayRegister: 0,
  totalRegister: 0,
  todayProposals: 0,
  totalProposals: 0,
  thisMonthProposals: 0,
  thisYearProposals: 0,
  thisMonthRegister: 0,
  thisYearRegister: 0,
  loadingDashboard: false,
  errorDashboard: null,
}

export const dashboardReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_DASHBOARD_REQUEST':
      return {
        ...state,
        loadingDashboard: true,
        errorDashboard: null,
      }
    case 'GET_DASHBOARD_SUCCESS':
      return {
        ...state,
        progressGender: action.dashboardData.progressGender,
        progressProposal: action.dashboardData.progressProposal,
        progressRequest: action.dashboardData.progressRequest,
        todayRegister: action.dashboardData.todayRegister,
        totalRegister: action.dashboardData.totalRegister,
        todayProposals: action.dashboardData.todayProposals,
        totalProposals: action.dashboardData.totalProposals,
        thisMonthProposals: action.dashboardData.thisMonthProposals,
        thisYearProposals: action.dashboardData.thisYearProposals,
        thisMonthRegister: action.dashboardData.thisMonthRegister,
        thisYearRegister: action.dashboardData.thisYearRegister,
        loadingDashboard: false,
        errorDashboard: null,
      }
    case 'GET_DASHBOARD_FAILURE':
      return {
        ...state,
        loadingDashboard: false,
        errorDashboard: action.error,
      }
    default:
      return state
  }
}
