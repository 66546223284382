import React from 'react'
import { CSpinner } from '@coreui/react'

const AppSpinner = () => {
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse">
        <CSpinner color="primary" />
      </div>
    </div>
  )
}

export default AppSpinner
