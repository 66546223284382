import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getMasterDataRequest = () => ({
  type: 'GET_MASTER_DATA_REQUEST',
})

export const getMasterDataSuccess = (data) => ({
  type: 'GET_MASTER_DATA_SUCCESS',
  data,
})

export const getMasterDataFailure = (error) => ({
  type: 'GET_MASTER_DATA_FAILURE',
  error,
})

export const addMasterDataRequest = () => ({
  type: 'ADD_MASTER_DATA_REQUEST',
})

export const addMasterDataSuccess = () => ({
  type: 'ADD_MASTER_DATA_SUCCESS',
})

export const addMasterDataFailure = (error) => ({
  type: 'ADD_MASTER_DATA_FAILURE',
  error,
})

export const updateMasterDataRequest = () => ({
  type: 'UPDATE_MASTER_DATA_REQUEST',
})

export const updateMasterDataSuccess = () => ({
  type: 'UPDATE_MASTER_DATA_SUCCESS',
})

export const updateMasterDataFailure = (error) => ({
  type: 'UPDATE_MASTER_DATA_FAILURE',
  error,
})

export const deleteMasterDataRequest = () => ({
  type: 'DELETE_MASTER_DATA_REQUEST',
})

export const deleteMasterDataSuccess = () => ({
  type: 'DELETE_MASTER_DATA_SUCCESS',
})

export const deleteMasterDataFailure = (error) => ({
  type: 'DELETE_MASTER_DATA_FAILURE',
  error,
})

export const getMasterData = (type) => {
  return (dispatch) => {
    dispatch(getMasterDataRequest())

    fetch(URL.BASE_URL + URL.END_POINT.masterdata + type, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getMasterDataSuccess(data))
      })
      .catch((error) => {
        dispatch(getMasterDataFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const addMasterData = (newData) => {
  return (dispatch) => {
    dispatch(addMasterDataRequest())

    fetch(URL.BASE_URL + URL.END_POINT.masterdata, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newData),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(addMasterDataSuccess())
        toast.success(`${newData.type} added successfully`, { type: 'success' })
      })
      .catch((error) => {
        dispatch(addMasterDataFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const updateMasterData = (id, updatedData) => {
  return (dispatch) => {
    dispatch(updateMasterDataRequest())

    fetch(URL.BASE_URL + URL.END_POINT.masterdata + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(updateMasterDataSuccess())
        toast.success(`${updatedData.type} updated successfully`, { type: 'success' })
      })
      .catch((error) => {
        dispatch(updateMasterDataFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const deleteMasterData = (id) => {
  return (dispatch) => {
    dispatch(deleteMasterDataRequest())

    fetch(URL.BASE_URL + URL.END_POINT.masterdata + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(deleteMasterDataSuccess())
        toast.success('Deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteMasterDataFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  masterData: [],
  loadingMasterData: false,
  errorMasterData: null,
}

export const getmasterReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_MASTER_DATA_REQUEST':
      return {
        ...state,
        loadingMasterData: true,
        errorMasterData: null,
      }
    case 'GET_MASTER_DATA_SUCCESS':
      return {
        ...state,
        masterData: action.data,
        loadingMasterData: false,
        errorMasterData: null,
      }
    case 'GET_MASTER_DATA_FAILURE':
      return {
        ...state,
        loadingMasterData: false,
        errorMasterData: action.error,
      }
    default:
      return state
  }
}

export const addmasterReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'ADD_MASTER_DATA_REQUEST':
      return {
        ...state,
        loadingMasterData: true,
        errorMasterData: null,
      }
    case 'ADD_MASTER_DATA_SUCCESS':
      return {
        ...state,
        loadingMasterData: false,
        errorMasterData: null,
      }
    case 'ADD_MASTER_DATA_FAILURE':
      return {
        ...state,
        loadingMasterData: false,
        errorMasterData: action.error,
      }
    default:
      return state
  }
}

export const updatemasterReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'UPDATE_MASTER_DATA_REQUEST':
      return {
        ...state,
        loadingMasterData: true,
        errorMasterData: null,
      }
    case 'UPDATE_MASTER_DATA_SUCCESS':
      return {
        ...state,
        loadingMasterData: false,
        errorMasterData: null,
      }
    case 'UPDATE_MASTER_DATA_FAILURE':
      return {
        ...state,
        loadingMasterData: false,
        errorMasterData: action.error,
      }
    default:
      return state
  }
}

export const deletemasterReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'DELETE_MASTER_DATA_REQUEST':
      return {
        ...state,
        loadingMasterData: true,
        errorMasterData: null,
      }
    case 'DELETE_MASTER_DATA_SUCCESS':
      return {
        ...state,
        loadingMasterData: false,
        errorMasterData: null,
      }
    case 'DELETE_MASTER_DATA_FAILURE':
      return {
        ...state,
        loadingMasterData: false,
        errorMasterData: action.error,
      }
    default:
      return state
  }
}
