import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cibGmail,
  cilLockLocked,
  cilMobile,
  cilPen,
  cilPeople,
  cilTrash,
  cilUser,
} from '@coreui/icons'
import imgPic from 'src/assets/images/profile.png'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminUsers } from 'src/redux/adminusers'
import { deleteAdminUser, signupUser, updateAdminUser } from 'src/redux/signup'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import { useTableSelection } from 'src/utils/useTableSelection'

const Adminusers = () => {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()

  const { adminUsers } = useSelector((state) => state.adminusers)

  const { selectedItems, toggleItemSelection, toggleSelectAll, isAllSelected } = useTableSelection(
    adminUsers?.admin_user,
  )

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setImage(URL.createObjectURL(file))
    setFormData({ ...formData, profile: file })
  }

  const [formData, setFormData] = useState({
    name: '',
    email_id: '',
    mobile_no: '',
    password: '',
    profile: '',
  })

  const { isSignedUp } = useSelector((state) => state.signup)

  useEffect(() => {
    dispatch(getAdminUsers())
    if (isSignedUp) {
      setFormData({
        name: '',
        email_id: '',
        mobile_no: '',
        password: '',
        profile: '',
      })
      setImage(null)
    }
  }, [dispatch, isSignedUp, loading])

  const handleRegister = async () => {
    if (!formData.profile) {
      toast('Picture required!', { type: 'info' })
    } else if (!formData.name) {
      toast('Name required!', { type: 'info' })
    } else if (!formData.email_id) {
      toast('Email required!', { type: 'info' })
    } else if (!formData.mobile_no) {
      toast('Mobile required!', { type: 'info' })
    } else if (!formData.password) {
      toast('Password required!', { type: 'info' })
    } else if (!formData.password || !formData.repeat_password) {
      toast('Password is required field', { type: 'info' })
    } else {
      const credentials = {
        name: formData.name,
        email_id: formData.email_id,
        mobile_no: formData.mobile_no,
        password: formData.password,
        profile: formData.profile,
      }
      const formDataToSend = new FormData()
      Object.keys(credentials).forEach((key) => {
        formDataToSend.append(key, credentials[key])
      })
      try {
        dispatch(signupUser(formDataToSend))
      } catch (error) {
        toast(error.message, { type: 'error' })
      }
    }
  }

  async function handleDelete(id) {
    setLoading(true)
    await dispatch(deleteAdminUser(id))
    setLoading(false)
  }

  const hideSensitiveInfo = (info) => {
    const hiddenInfo = '*'.repeat(info?.length - 0) + info?.slice(10)
    return hiddenInfo
  }

  const enableEditMode = (item) => {
    let clone = { ...item, password: '' }
    setFormData(clone)
    setIsEdit(true)
    setImage(item.profile)
  }

  const disableEditMode = () => {
    setIsEdit(false)
    setFormData({
      name: '',
      email_id: '',
      mobile_no: '',
      password: '',
      profile: '',
      repeat_password: '',
    })
    setImage(null)
  }

  const handleUpdateRegister = async () => {
    if (!formData.password || !formData.repeat_password) {
      toast.warning('Password is required field')
    }
    if (formData.repeat_password === formData.password) {
      setLoading(true)
      await dispatch(updateAdminUser(formData))
      setLoading(false)
      disableEditMode()
    } else {
      toast.warning('Password is not matched')
    }
  }

  return (
    <div className="bg-light d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={8}>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>
                    <CFormCheck
                      checked={isAllSelected}
                      onChange={toggleSelectAll}
                      color="primary"
                    />
                  </CTableHeaderCell>
                  <CTableHeaderCell className="text-center">
                    <CIcon icon={cilPeople} />
                  </CTableHeaderCell>
                  <CTableHeaderCell>Username</CTableHeaderCell>
                  <CTableHeaderCell>Email Address</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Mobile</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Password</CTableHeaderCell>
                  <CTableHeaderCell>Status</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {adminUsers?.admin_user?.map((item, index) => (
                  <CTableRow v-for="item in tableItems" key={index}>
                    {/* <CTableDataCell>{index + 1})</CTableDataCell> */}
                    <CTableDataCell>
                      <CFormCheck
                        checked={selectedItems.includes(item.id)}
                        onChange={() => toggleItemSelection(item.id)}
                        color="primary"
                      />
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div className="image-size">
                        <CAvatar size="md" src={item.profile} className="image-profile" />
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div>{item.name}</div>
                      <div className="small text-medium-emphasis">
                        <strong>{item.username} </strong> | {item.user_type}
                      </div>
                    </CTableDataCell>

                    <CTableDataCell>
                      <div className="clearfix">
                        <small className="text-medium-emphasis">{item.email_id}</small>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div className="clearfix">
                        <small className="text-medium-emphasis">{item.mobile_no}</small>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div className="clearfix">
                        <small className="text-medium-emphasis">
                          {hideSensitiveInfo(String(item.password))}
                        </small>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="small text-medium-emphasis">{item.status}</div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <CButtonGroup role="group">
                        <CButton color="warning" size="sm" onClick={() => enableEditMode(item)}>
                          <CIcon icon={cilPen} className="text-white" />
                        </CButton>
                        <CButton
                          color="danger"
                          size="sm"
                          onClick={() => handleDelete(item.user_code)}
                        >
                          <CIcon icon={cilTrash} className="text-white" />
                        </CButton>
                        {/* <CButton color="success" size="sm">
                          <CIcon icon={cilInfo} className="text-white" />
                        </CButton> */}
                      </CButtonGroup>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
          <CCol md={9} lg={7} xl={4}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm>
                  <h1>Admin users</h1>
                  <p className="text-medium-emphasis">
                    {isEdit ? 'Update' : 'Create'} user account
                  </p>
                  <CImage
                    src={image ? image : imgPic}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 100,
                      justifyContent: 'center',
                    }}
                  />
                  <div className="my-3">
                    <CFormInput
                      type="file"
                      size="sm"
                      id="pickImage"
                      disabled={isEdit}
                      onChange={handleImageChange}
                    />
                  </div>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                      placeholder="Name"
                      autoComplete="name"
                      value={formData.name}
                      disabled={isEdit}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cibGmail} />
                    </CInputGroupText>
                    <CFormInput
                      placeholder="Email"
                      autoComplete="email"
                      value={formData.email_id}
                      disabled={isEdit}
                      onChange={(e) => setFormData({ ...formData, email_id: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilMobile} />
                    </CInputGroupText>
                    <CFormInput
                      placeholder="Mobile"
                      autoComplete="mobile"
                      value={formData.mobile_no}
                      disabled={isEdit}
                      onChange={(e) => setFormData({ ...formData, mobile_no: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      value={formData.password}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Repeat password"
                      autoComplete="new-password"
                      value={formData.repeat_password}
                      onChange={(e) =>
                        setFormData({ ...formData, repeat_password: e.target.value })
                      }
                    />
                  </CInputGroup>
                  <div className="d-grid mb-3">
                    <CButton
                      color="success"
                      onClick={() => (!isEdit ? handleRegister() : handleUpdateRegister())}
                    >
                      {isEdit ? 'Update' : 'Create'} Account
                    </CButton>
                  </div>
                  {isEdit && (
                    <div className="d-grid">
                      <CButton color="secondary" onClick={disableEditMode}>
                        Switch to Create Mode
                      </CButton>
                    </div>
                  )}
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Adminusers
