import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getPaymentHistoryRequest = () => ({
  type: 'GET_PAYMENT_HISTORY_REQUEST',
})

export const getPaymentHistorySuccess = (data) => ({
  type: 'GET_PAYMENT_HISTORY_SUCCESS',
  data,
})

export const getPaymentHistoryFailure = (error) => ({
  type: 'GET_PAYMENT_HISTORY_FAILURE',
  error,
})

export const getPaymentHistory = () => {
  return (dispatch) => {
    dispatch(getPaymentHistoryRequest())

    fetch(URL.BASE_URL + URL.END_POINT.paymenthistory, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getPaymentHistorySuccess(data))
      })
      .catch((error) => {
        dispatch(getPaymentHistoryFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  paymentHistory: {},
  loadingPaymentHistory: false,
  errorPaymentHistory: null,
}

const paymentHistoryReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_PAYMENT_HISTORY_REQUEST':
      return {
        ...state,
        loadingPaymentHistory: true,
        errorPaymentHistory: null,
      }
    case 'GET_PAYMENT_HISTORY_SUCCESS':
      return {
        ...state,
        paymentHistory: action.data,
        loadingPaymentHistory: false,
        errorPaymentHistory: null,
      }
    case 'GET_PAYMENT_HISTORY_FAILURE':
      return {
        ...state,
        loadingPaymentHistory: false,
        errorPaymentHistory: action.error,
      }
    default:
      return state
  }
}

export default paymentHistoryReducer
