import { useState, useCallback } from 'react'

export const useTableSelection = (items = []) => {
  const [selectedItems, setSelectedItems] = useState([])

  const toggleItemSelection = useCallback((id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    )
  }, [])

  const toggleSelectAll = useCallback(() => {
    if (selectedItems.length === items.length) {
      setSelectedItems([])
    } else {
      setSelectedItems(items.map((item) => item.id))
    }
  }, [items, selectedItems.length])

  const isAllSelected = items.length > 0 && selectedItems.length === items?.length

  return {
    selectedItems,
    toggleItemSelection,
    toggleSelectAll,
    isAllSelected,
  }
}
