export const excelFileNamer = (all) => {
  const date = new Date()
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()

  let fileName = all + 'Report' + ' ' + month + ', ' + year

  return fileName
}
