import React, { useEffect, useState } from 'react'
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilPencil, cilTrash } from '@coreui/icons'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  addMasterData,
  deleteMasterData,
  getMasterData,
  updateMasterData,
} from 'src/redux/masterdata'

const ResidenceStatusData = () => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    type: 'Residence Status',
    name: '',
    description: '',
  })

  const dispatch = useDispatch()

  const { masterData } = useSelector((state) => state.getmasterdata)

  useEffect(() => {
    dispatch(getMasterData(formData.type))
  }, [dispatch, formData.type, loading])

  const handleSubmit = async () => {
    if (!formData.type) {
      toast('Type required!', { type: 'info' })
    } else if (!formData.name) {
      toast('Name required!', { type: 'info' })
    } else if (!formData.description) {
      toast('Description required!', { type: 'info' })
    } else {
      setLoading(true)
      try {
        await dispatch(addMasterData(formData))
        setFormData({
          type: 'Residence Status',
          name: '',
          description: '',
        })
      } catch (error) {
        toast(error.message, { type: 'error' })
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleDelete(id) {
    setLoading(true)
    await dispatch(deleteMasterData(id))
    setLoading(false)
  }

  async function handleUpdate() {
    setLoading(true)
    await dispatch(updateMasterData(formData?.id, formData))
    setFormData({
      type: 'Residence Status',
      name: '',
      description: '',
    })
    setLoading(false)
  }

  return (
    <div className="bg-light d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={8}>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell className="text-center"></CTableHeaderCell>
                  <CTableHeaderCell>{formData.type} Name</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Description</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {masterData?.map((item, index) => (
                  <CTableRow v-for="item in tableItems" key={index}>
                    <CTableDataCell className="text-center">
                      <div className="clearfix">
                        <small className="text-medium-emphasis">{index + 1}) </small>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="clearfix">
                        <h6 className="text-medium-emphasis">{item.name}</h6>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div className="clearfix">
                        <small className="text-medium-emphasis">{item.description}</small>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell className="text-center">
                      <CButtonGroup role="group" aria-label="Basic mixed styles example">
                        <CButton color="danger" size="sm" onClick={() => handleDelete(item.id)}>
                          <CIcon icon={cilTrash} className="text-white" />
                        </CButton>
                        <CButton color="warning" size="sm" onClick={() => setFormData(item)}>
                          <CIcon icon={cilPencil} className="text-dark" />
                        </CButton>
                      </CButtonGroup>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
          <CCol md={9} lg={7} xl={4}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm>
                  <h5 className="text-medium-emphasis mb-3">
                    {formData?.id ? 'Update' : 'Add'} {formData.type}
                  </h5>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder={formData.type}
                      autoComplete={formData.type}
                      value={formData.type}
                      onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                      disabled
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Name"
                      autoComplete="Name"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Description"
                      autoComplete="Description"
                      value={formData.description}
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    />
                  </CInputGroup>
                  <div className="d-grid">
                    <CButton color="success" onClick={formData?.id ? handleUpdate : handleSubmit}>
                      {formData?.id ? 'Update' : 'Submit'}
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResidenceStatusData
