import React, { useEffect, useState } from 'react'
import {
  CButton,
  CButtonGroup,
  CCol,
  CContainer,
  CFormCheck,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Pagination from 'react-js-pagination'
import { AppSpinner } from 'src/components'
import { useTableSelection } from 'src/utils/useTableSelection'
import { blockUser, getBlockedUsers } from 'src/redux/blockusers'

const Blockusers = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 20
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()

  const { blockedUsersData, isLoading } = useSelector((state) => {
    return {
      blockedUsersData: state?.blockusers?.blockedUsersData,
      isLoading: state?.blockusers?.loadingBlockedUsersData,
    }
  })

  const { selectedItems, toggleItemSelection, toggleSelectAll, isAllSelected } = useTableSelection(
    blockedUsersData?.app_users,
  )

  useEffect(() => {
    dispatch(getBlockedUsers(search, currentPage))
  }, [search, currentPage, dispatch])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleBlock = (id) => {
    dispatch(blockUser(id))
  }

  return (
    <div className="bg-light d-flex flex-row align-items-center">
      <CContainer>
        <div className="d-flex justify-content-end mb-2">
          <CFormInput
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {isLoading ? (
          <CContainer className="d-flex justify-content-center align-items-center">
            <AppSpinner />
          </CContainer>
        ) : (
          <>
            <CRow className="justify-content-center">
              <CCol md={12} lg={12} xl={12}>
                <PerfectScrollbar style={{ height: '100%' }}>
                  <CTable align="middle" className="mb-2 border" hover responsive>
                    <CTableHead color="light">
                      <CTableRow>
                        <CTableHeaderCell>
                          <CFormCheck
                            checked={isAllSelected}
                            onChange={toggleSelectAll}
                            color="primary"
                          />
                        </CTableHeaderCell>
                        <CTableHeaderCell>Name</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Mobile</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Email</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Country</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {blockedUsersData?.app_users?.map((item, index) => (
                        <CTableRow v-for="item in tableItems" key={index}>
                          {/* <CTableDataCell className="text-center">
                        <div className="clearfix">
                          <small className="text-medium-emphasis">{index + 1}) </small>
                        </div>
                      </CTableDataCell> */}
                          <CTableDataCell>
                            <CFormCheck
                              checked={selectedItems.includes(item.id)}
                              onChange={() => toggleItemSelection(item.id)}
                              color="primary"
                            />
                          </CTableDataCell>
                          <CTableDataCell>
                            <div className="clearfix">
                              <strong>{item.name}</strong>
                            </div>
                          </CTableDataCell>

                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="">{item.mobile_no}</h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="">{item.email_id}</h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="">{item.country}</h6>
                            </div>
                          </CTableDataCell>
                          {/* <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="">{DateTimeConverter(item.createdAt)}</h6>
                            </div>
                          </CTableDataCell> */}
                          <CTableDataCell className="text-center">
                            <CButtonGroup role="group" aria-label="Basic mixed styles example">
                              <CButton
                                color="success"
                                size="sm"
                                onClick={() => handleBlock(item.id)}
                              >
                                Unblock
                              </CButton>
                            </CButtonGroup>
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                </PerfectScrollbar>
              </CCol>
            </CRow>
            <ul className="pagination justify-content-center">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={blockedUsersData?.total || 0}
                hideFirstLastPages={false}
                onChange={handlePageChange}
                pageRangeDisplayed={5}
                nextPageText="Next"
                prevPageText="Previous"
                lastPageText="Last"
                firstPageText="1st"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="active"
                activeLinkClass="active"
                hideDisabled={false}
              />
            </ul>
          </>
        )}
      </CContainer>
    </div>
  )
}

export default Blockusers
