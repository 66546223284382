export const sidebarShowReducer = (state = true, action) => {
  switch (action.type) {
    case 'SET_SIDEBAR_SHOW':
      return action.sidebarShow
    default:
      return state
  }
}

export const sidebarUnfoldableReducer = (state = false, action) => {
  switch (action.type) {
    case 'SET_SIDEBAR_UNFOLDABLE':
      return action.sidebarUnfoldable
    default:
      return state
  }
}
