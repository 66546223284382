import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilPencil, cilTrash } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  addBankDetails,
  deleteBankDetails,
  editBankDetails,
  getBankDetails,
} from 'src/redux/bankdetails'
import imgPic from 'src/assets/images/profile.png'
import { AppSpinner } from 'src/components'

const Bankdetails = () => {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setImage(URL.createObjectURL(file))
    setFormData({ ...formData, icon: file })
  }

  const [formData, setFormData] = useState({
    account_title: '',
    bank_name: '',
    branch_code: '',
    account_no: '',
    iban_no: '',
    icon: '',
  })

  const dispatch = useDispatch()

  const { bankDetails, isLoading } = useSelector((state) => {
    return { bankDetails: state.bankdetails, isLoading: state.bankdetails?.loadingBankDetails }
  })

  useEffect(() => {
    dispatch(getBankDetails())
  }, [dispatch, loading])

  const handleSubmit = async () => {
    if (!formData.account_title) {
      toast('Account Title required!', { type: 'info' })
    } else if (!formData.bank_name) {
      toast('Bank Name required!', { type: 'info' })
    } else if (!formData.account_no) {
      toast('Account No required!', { type: 'info' })
    } else if (!formData.icon) {
      toast('Icon required!', { type: 'info' })
    } else {
      setLoading(true)
      const bodyData = {
        account_title: formData.account_title,
        bank_name: formData.bank_name,
        branch_code: formData.branch_code,
        account_no: formData.account_no,
        iban_no: formData.iban_no,
        icon: formData.icon,
      }
      const formDataToSend = new FormData()
      Object.keys(bodyData).forEach((key) => {
        formDataToSend.append(key, bodyData[key])
      })
      try {
        await dispatch(addBankDetails(formDataToSend))
        setFormData({
          account_title: '',
          bank_name: '',
          branch_code: '',
          account_no: '',
          iban_no: '',
          icon: '',
        })
        setImage(null)
      } catch (error) {
        toast(error.message, { type: 'error' })
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleDelete(id) {
    setLoading(true)
    await dispatch(deleteBankDetails(id))
    setLoading(false)
  }

  async function handleUpdate() {
    if (!formData.account_title) {
      toast('Account Title required!', { type: 'info' })
    } else if (!formData.bank_name) {
      toast('Bank Name required!', { type: 'info' })
    } else if (!formData.account_no) {
      toast('Account No required!', { type: 'info' })
    } else if (!formData.icon) {
      toast('Icon required!', { type: 'info' })
    } else {
      setLoading(true)
      const bodyData = {
        account_title: formData.account_title,
        bank_name: formData.bank_name,
        branch_code: formData.branch_code,
        account_no: formData.account_no,
        iban_no: formData.iban_no,
        icon: formData.icon,
      }
      const formDataToSend = new FormData()
      Object.keys(bodyData).forEach((key) => {
        formDataToSend.append(key, bodyData[key])
      })
      try {
        await dispatch(editBankDetails(formData?.id, formDataToSend))
        setFormData({
          account_title: '',
          bank_name: '',
          branch_code: '',
          account_no: '',
          iban_no: '',
          icon: '',
        })
        setImage(null)
      } catch (error) {
        toast(error.message, { type: 'error' })
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <div className="bg-light d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={8}>
            {isLoading ? (
              <CContainer className="d-flex justify-content-center align-items-center">
                <AppSpinner />
              </CContainer>
            ) : (
              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell className="text-center"></CTableHeaderCell>
                    <CTableHeaderCell className="text-center"></CTableHeaderCell>
                    <CTableHeaderCell>Bank name</CTableHeaderCell>
                    <CTableHeaderCell>Account</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Account No</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">IBAN No</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {bankDetails?.bankDetails?.bank_details?.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index}>
                      <CTableDataCell>{index + 1})</CTableDataCell>
                      <CTableDataCell className="text-center">
                        <div className="image-size">
                          <CAvatar size="md" src={item.icon} className="image-profile" />
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{item.bank_name}</div>
                        <div className="small text-medium-emphasis">
                          <strong>{item.branch_code && 'Branch code ' + item.branch_code}</strong>
                        </div>
                      </CTableDataCell>

                      <CTableDataCell>
                        <div className="clearfix">
                          <small className="text-medium-emphasis">{item.account_title}</small>
                        </div>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <div className="clearfix">
                          <small className="text-medium-emphasis">{item.account_no}</small>
                        </div>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <div className="clearfix">
                          <small className="text-medium-emphasis">{item.iban_no}</small>
                        </div>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <CButtonGroup role="group" aria-label="Basic mixed styles example">
                          <CButton color="warning" size="sm" onClick={() => setFormData(item)}>
                            <CIcon icon={cilPencil} className="text-white" />
                          </CButton>
                          <CButton color="danger" size="sm" onClick={() => handleDelete(item.id)}>
                            <CIcon icon={cilTrash} className="text-white" />
                          </CButton>
                        </CButtonGroup>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            )}
          </CCol>
          <CCol md={9} lg={7} xl={4}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm>
                  <h5 className="text-medium-emphasis mb-3">Add Bank Account</h5>
                  <CImage
                    src={image ? image : formData?.id ? formData.icon : imgPic}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 100,
                      justifyContent: 'center',
                    }}
                  />
                  <div className="my-3">
                    <CFormInput type="file" size="sm" id="pickImage" onChange={handleImageChange} />
                  </div>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Account Title"
                      autoComplete="Account Title"
                      value={formData.account_title}
                      onChange={(e) => setFormData({ ...formData, account_title: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Bank Name"
                      autoComplete="Bank Name"
                      value={formData.bank_name}
                      onChange={(e) => setFormData({ ...formData, bank_name: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Branch Code"
                      autoComplete="Branch Code"
                      value={formData.branch_code}
                      onChange={(e) => setFormData({ ...formData, branch_code: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="IBAN No"
                      autoComplete="IBAN No"
                      value={formData.iban_no}
                      onChange={(e) => setFormData({ ...formData, iban_no: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <CFormInput
                      placeholder="Account No"
                      autoComplete="Account No"
                      value={formData.account_no}
                      onChange={(e) => setFormData({ ...formData, account_no: e.target.value })}
                    />
                  </CInputGroup>
                  <div className="d-grid">
                    <CButton color="success" onClick={formData?.id ? handleUpdate : handleSubmit}>
                      {formData?.id ? 'Update' : 'Submit'}
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Bankdetails
