import { cilArrowLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
} from '@coreui/react'
import React, { useState } from 'react'
import data from '../../../assets/data/data.json'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { registerUser } from 'src/redux/registration'
import { useEffect } from 'react'
import { getCountry } from 'src/redux/country'
import { getStates } from 'src/redux/states'
import { getCities } from 'src/redux/cities'
import { getSingleAppUser } from 'src/redux/appusers'

const Editusers = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setImage(URL.createObjectURL(file))
    setFormData({ ...formData, profile: file })
  }

  const [formData, setFormData] = useState({
    name: '',
    sex: '',
    age: '',
    height: '',
    skintone: '',
    martial_status: '',
    any_disability: '',
    nationality: '',
    whatsapp_no: '',
    country: '',
    state: '',
    city: '',
    religion: '',
    sect_firqa: '',
    community: '',
    cast: '',
    father_name: '',
    father_status: '',
    father_company_name: '',
    father_designation: '',
    mother_status: '',
    no_of_brother: '',
    brother_status: '',
    no_of_sister: '',
    sister_status: '',
    occu_of_bro_sis: '',
    edu_level: '',
    degree: '',
    institute: '',
    edu_summary: '',
    working_sector: '',
    profession_job: '',
    total_income: '',
    residence_living_status: '',
    residence_status: '',
    residence_size: '',
    residence_country: '',
    residence_state: '',
    residence_city: '',
    residence_area: '',
    hometown_living_status: '',
    hometown_status: '',
    hometown_size: '',
    hometown_country: '',
    hometown_state: '',
    hometown_city: '',
    hometown_area: '',
    profile_pic: '',
    seen_profile_pic: '',
    seen_photos: '',
    partner_age_between: '',
    partner_marital_status: '',
    partner_nationality: '',
    partner_religion: '',
    partner_sect_firqa: '',
    partner_community: '',
    partner_cast: '',
    partner_skintone: '',
    partner_any_disability: '',
    partner_edu_level: '',
    partner_degree: '',
    partner_working_sector: '',
    partner_profession_job: '',
    partner_residence_status: '',
    partner_residence_country: '',
    partner_residence_state: '',
    partner_residence_city: '',
    my_selfie: '',
    front_id_card: '',
    back_id_card: '',
  })

  const dispatch = useDispatch()
  const { id } = useParams()
  const { singleAppUser } = useSelector((state) => state.appuser)

  useEffect(() => {
    dispatch(getSingleAppUser(id))
  }, [dispatch, id])

  useEffect(() => {
    setFormData(singleAppUser)
  }, [dispatch, singleAppUser])

  const goBack = () => {
    window.history.back()
  }

  const [countryCode, setCountryCode] = useState('')
  const [stateCode, setStateCode] = useState('')

  // const { countryData } = useSelector((state) => state.country)
  // const { statesData } = useSelector((state) => state.states)
  // const { citiesData } = useSelector((state) => state.cities)

  useEffect(() => {
    dispatch(getCountry())
  }, [dispatch])

  useEffect(() => {
    dispatch(getStates(countryCode))
  }, [dispatch, countryCode])

  useEffect(() => {
    dispatch(getCities(`${countryCode}/${stateCode}`))
  }, [dispatch, stateCode])

  const [validated, setValidated] = useState(false)
  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    const formDataToSend = new FormData()
    Object.keys(formData).forEach((key) => {
      if (
        key !== 'username' &&
        key !== 'password' &&
        key !== 'mobile_no' &&
        key !== 'mobile_otp' &&
        key !== 'mobile_verify' &&
        key !== 'email_id' &&
        key !== 'email_otp' &&
        key !== 'email_verify' &&
        key !== 'user_type' &&
        key !== 'is_online' &&
        key !== 'fcm_token' &&
        key !== 'reset_token' &&
        key !== 'reset_expiry' &&
        key !== 'connections' &&
        key !== 'createdAt' &&
        key !== 'updatedAt' &&
        key !== 'profile_pic' &&
        key !== 'seen_email_id' &&
        key !== 'seen_mobile_no' &&
        key !== 'seen_whatsapp_no' &&
        key !== 'thumbnail'
      ) {
        formDataToSend.append(key, formData[key])
      }
    })
    try {
      await dispatch(registerUser(formDataToSend, id, navigate))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const numberArray = Array.from({ length: 83 }, (_, index) => 18 + index)

  return (
    <CContainer>
      <div className="d-flex justify-content-end">
        <CButton color="secondary" size="sm" className="text-white" onClick={() => goBack()}>
          <CIcon icon={cilArrowLeft} className="text-white" /> Go Back
        </CButton>
      </div>

      <CRow className="justify-content-center my-2">
        <CCol md={9} lg={7} xl={12}>
          {id && (
            <CForm
              className="needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CCard className="px-4 mt-2">
                <CCardBody className="p-4 row g-3">
                  <CCol md={4}>
                    <CFormInput
                      id="name"
                      type="text"
                      label="Full Name"
                      placeholder="Enter name"
                      feedbackInvalid="Please provide a valid name"
                      value={formData?.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="sex"
                      label="Gender"
                      feedbackInvalid="Please provide a valid gender"
                      value={formData?.sex}
                      onChange={(e) => setFormData({ ...formData, sex: e.target.value })}
                      required
                    >
                      <option disabled>Select gender</option>
                      {['Male', 'Female'].map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="age"
                      label="Age"
                      feedbackInvalid="Please provide a valid age"
                      value={formData?.age}
                      onChange={(e) => setFormData({ ...formData, age: e.target.value })}
                      required
                    >
                      <option disabled>Select age</option>
                      {numberArray.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="height"
                      label="Height"
                      feedbackInvalid="Please provide a valid height"
                      value={formData?.height}
                      onChange={(e) => setFormData({ ...formData, height: e.target.value })}
                      required
                    >
                      <option disabled>Select height</option>
                      {data?.heightData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="skintone"
                      label="Skintone"
                      feedbackInvalid="Please provide a valid skintone"
                      value={formData?.skintone}
                      onChange={(e) => setFormData({ ...formData, skintone: e.target.value })}
                      required
                    >
                      <option disabled>Select skintone</option>
                      {data?.skintoneArray.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="martial_status"
                      label="Marital Status"
                      feedbackInvalid="Please provide a valid marital status"
                      value={formData?.martial_status}
                      onChange={(e) => setFormData({ ...formData, martial_status: e.target.value })}
                      required
                    >
                      <option disabled>Select marital status</option>
                      {data?.maritalStatusOptions.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="any_disability"
                      type="text"
                      label="Any Disability"
                      placeholder="Enter disability status"
                      feedbackInvalid="Please provide a valid disability status"
                      value={formData?.any_disability}
                      onChange={(e) => setFormData({ ...formData, any_disability: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="nationality"
                      type="text"
                      label="Nationality"
                      placeholder="Enter nationality"
                      feedbackInvalid="Please provide a valid nationality"
                      value={formData?.nationality}
                      onChange={(e) => setFormData({ ...formData, nationality: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="whatsapp_no"
                      type="text"
                      label="WhatsApp Number"
                      placeholder="Enter WhatsApp number"
                      feedbackInvalid="Please provide a valid WhatsApp number"
                      value={formData?.whatsapp_no}
                      onChange={(e) => setFormData({ ...formData, whatsapp_no: e.target.value })}
                      required
                    />
                  </CCol>
                </CCardBody>
              </CCard>
              <CCard className="px-4 mt-2">
                <CCardBody className="p-4 row g-3">
                  {/* <CCol md={4}>
                    <CFormSelect
                      id="country"
                      label="Country"
                      feedbackInvalid="Please provide a valid country"
                      value={formData?.country}
                      onChange={(e) => {
                        countryData?.map(
                          ({ name, isoCode }) => name === e.target.value && setCountryCode(isoCode),
                        )
                        setFormData({
                          ...formData,
                          country: e.target.value,
                          state: '',
                          city: '',
                        })
                      }}
                      required
                    >
                      <option>Select country</option>
                      {countryData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="state"
                      label="State"
                      feedbackInvalid="Please provide a valid state"
                      value={formData?.state}
                      onChange={(e) => {
                        statesData?.map(
                          ({ name, isoCode }) => name === e.target.value && setStateCode(isoCode),
                        )
                        setFormData({
                          ...formData,
                          state: e.target.value,
                          city: '',
                        })
                      }}
                      required
                    >
                      <option>Select state</option>
                      {statesData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="city"
                      label="City"
                      feedbackInvalid="Please provide a valid city"
                      value={formData?.city}
                      onChange={(e) => {
                        citiesData?.map(({ name }) => name === e.target.value)
                        setFormData({
                          ...formData,
                          city: e.target.value,
                        })
                      }}
                      required
                    >
                      <option>Select city</option>
                      {citiesData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol> */}
                  <CCol md={4}>
                    <CFormSelect
                      id="religion"
                      label="Religion"
                      feedbackInvalid="Please provide a valid religion"
                      value={formData?.religion}
                      onChange={(e) => setFormData({ ...formData, religion: e.target.value })}
                      required
                    >
                      <option disabled>Select religion</option>
                      {data?.ReligionData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="sect_firqa"
                      label="Sect/Firqa"
                      feedbackInvalid="Please provide a valid sect/firqa"
                      value={formData?.sect_firqa}
                      onChange={(e) => setFormData({ ...formData, sect_firqa: e.target.value })}
                      required
                    >
                      <option disabled>Select sect/firqa</option>
                      {data?.SectData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="community"
                      label="Community"
                      feedbackInvalid="Please provide a valid community"
                      value={formData?.community}
                      onChange={(e) => setFormData({ ...formData, community: e.target.value })}
                      required
                    >
                      <option disabled>Select community</option>
                      {data?.CommunityData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="cast"
                      label="Cast"
                      feedbackInvalid="Please provide a valid cast"
                      value={formData?.cast}
                      onChange={(e) => setFormData({ ...formData, cast: e.target.value })}
                      required
                    >
                      <option disabled>Select cast</option>
                      {data?.CasteData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="father_name"
                      type="text"
                      label="Father's Name"
                      placeholder="Enter father's name"
                      feedbackInvalid="Please provide a valid father's name"
                      value={formData?.father_name}
                      onChange={(e) => setFormData({ ...formData, father_name: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="father_status"
                      label="Father's Status"
                      feedbackInvalid="Please provide a valid father's status"
                      value={formData?.father_status}
                      onChange={(e) => setFormData({ ...formData, father_status: e.target.value })}
                      required
                    >
                      <option disabled>Select father status</option>
                      {data?.FatherStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="father_company_name"
                      type="text"
                      label="Father's Company Name"
                      placeholder="Enter father's company name"
                      feedbackInvalid="Please provide a valid father's company name"
                      value={formData?.father_company_name}
                      onChange={(e) =>
                        setFormData({ ...formData, father_company_name: e.target.value })
                      }
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="father_designation"
                      type="text"
                      label="Father's Designation"
                      placeholder="Enter father's designation"
                      feedbackInvalid="Please provide a valid father's designation"
                      value={formData?.father_designation}
                      onChange={(e) =>
                        setFormData({ ...formData, father_designation: e.target.value })
                      }
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="mother_status"
                      label="Mother's Status"
                      feedbackInvalid="Please provide a valid mother's status"
                      value={formData?.mother_status}
                      onChange={(e) => setFormData({ ...formData, mother_status: e.target.value })}
                      required
                    >
                      <option disabled>Select mother status</option>
                      {data?.MotherStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="no_of_brother"
                      label="Number of Brothers"
                      feedbackInvalid="Please provide a valid number of brothers"
                      value={formData?.no_of_brother}
                      onChange={(e) => setFormData({ ...formData, no_of_brother: e.target.value })}
                      required
                    >
                      <option disabled>Select number of brothers</option>
                      {data?.BrotherStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="brother_status"
                      label="Brother's Status"
                      feedbackInvalid="Please provide a valid brother's status"
                      value={formData?.brother_status}
                      onChange={(e) => setFormData({ ...formData, brother_status: e.target.value })}
                      required
                    >
                      <option disabled>Select brother status</option>
                      {data?.MarriedStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="no_of_sister"
                      label="Number of Sisters"
                      feedbackInvalid="Please provide a valid number of sisters"
                      value={formData?.no_of_sister}
                      onChange={(e) => setFormData({ ...formData, no_of_sister: e.target.value })}
                      required
                    >
                      <option disabled>Select number of sisters</option>
                      {data?.SisterStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="sister_status"
                      label="Sister's Status"
                      feedbackInvalid="Please provide a valid sister's status"
                      value={formData?.sister_status}
                      onChange={(e) => setFormData({ ...formData, sister_status: e.target.value })}
                      required
                    >
                      <option disabled>Select sister status</option>
                      {data?.MarriedStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="occu_of_bro_sis"
                      type="text"
                      label="Occupation of Brothers/Sisters"
                      placeholder="Enter occupation of brothers/sisters"
                      feedbackInvalid="Please provide a valid occupation of brothers/sisters"
                      value={formData?.occu_of_bro_sis}
                      onChange={(e) =>
                        setFormData({ ...formData, occu_of_bro_sis: e.target.value })
                      }
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="edu_level"
                      label="Education Level"
                      feedbackInvalid="Please provide a valid education level"
                      value={formData?.edu_level}
                      onChange={(e) => setFormData({ ...formData, edu_level: e.target.value })}
                      required
                    >
                      <option disabled>Select education</option>
                      {data?.EducationLevelData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="degree"
                      label="Degree"
                      feedbackInvalid="Please provide a valid degree"
                      value={formData?.degree}
                      onChange={(e) => setFormData({ ...formData, degree: e.target.value })}
                      required
                    >
                      <option disabled>Select degree</option>
                      {data?.DegreeTitleData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="institute"
                      type="text"
                      label="Institute"
                      placeholder="Enter institute"
                      feedbackInvalid="Please provide a valid institute"
                      value={formData?.institute}
                      onChange={(e) => setFormData({ ...formData, institute: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="edu_summary"
                      type="text"
                      label="Education Summary"
                      placeholder="Enter education summary"
                      feedbackInvalid="Please provide a valid education summary"
                      value={formData?.edu_summary}
                      onChange={(e) => setFormData({ ...formData, edu_summary: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="working_sector"
                      label="Working Sector"
                      feedbackInvalid="Please provide a valid working sector"
                      value={formData?.working_sector}
                      onChange={(e) => setFormData({ ...formData, working_sector: e.target.value })}
                      required
                    >
                      <option disabled>Select working sector</option>
                      {data?.WorkingSectorData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="profession_job"
                      type="text"
                      label="Profession/Job"
                      placeholder="Enter profession/job"
                      feedbackInvalid="Please provide a valid profession/job"
                      value={formData?.profession_job}
                      onChange={(e) => setFormData({ ...formData, profession_job: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="total_income"
                      type="text"
                      label="Total Income"
                      placeholder="Enter total income"
                      feedbackInvalid="Please provide a valid total income"
                      value={formData?.total_income}
                      onChange={(e) => setFormData({ ...formData, total_income: e.target.value })}
                      required
                    />
                  </CCol>
                </CCardBody>
              </CCard>
              <CCard className="px-4 mt-2">
                <CCardBody className="p-4 row g-3 ">
                  <CCol md={4}>
                    <CFormSelect
                      id="residence_living_status"
                      label="Residence Living Status"
                      feedbackInvalid="Please provide a valid residence living status"
                      value={formData?.residence_living_status}
                      onChange={(e) =>
                        setFormData({ ...formData, residence_living_status: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select living status</option>
                      {data?.LivingStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="residence_status"
                      label="Residence Status"
                      feedbackInvalid="Please provide a valid residence status"
                      value={formData?.residence_status}
                      onChange={(e) =>
                        setFormData({ ...formData, residence_status: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select residence status</option>
                      {data?.ResidenceStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="residence_size"
                      label="Residence Size"
                      feedbackInvalid="Please provide a valid residence size"
                      value={formData?.residence_size}
                      onChange={(e) => setFormData({ ...formData, residence_size: e.target.value })}
                      required
                    >
                      <option disabled>Select residence size</option>
                      {data?.ResidenceSizeData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  {/* <CCol md={4}>
                    <CFormSelect
                      id="residence_country"
                      label="Residence Country"
                      feedbackInvalid="Please provide a valid residence country"
                      value={formData?.residence_country}
                      onChange={(e) => {
                        countryData?.map(
                          ({ name, isoCode }) => name === e.target.value && setCountryCode(isoCode),
                        )
                        setFormData({
                          ...formData,
                          residence_country: e.target.value,
                          residence_state: '',
                          residence_city: '',
                        })
                      }}
                      required
                    >
                      <option>Select country</option>
                      {countryData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="residence_state"
                      label="Residence State"
                      feedbackInvalid="Please provide a valid residence state"
                      value={formData?.residence_state}
                      onChange={(e) => {
                        statesData?.map(
                          ({ name, isoCode }) => name === e.target.value && setStateCode(isoCode),
                        )
                        setFormData({
                          ...formData,
                          residence_state: e.target.value,
                          residence_city: '',
                        })
                      }}
                      required
                    >
                      <option>Select state</option>
                      {statesData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="residence_city"
                      label="Residence City"
                      feedbackInvalid="Please provide a valid residence city"
                      value={formData?.residence_city}
                      onChange={(e) => {
                        citiesData?.map(({ name }) => name === e.target.value)
                        setFormData({
                          ...formData,
                          residence_city: e.target.value,
                        })
                      }}
                      required
                    >
                      <option>Select city</option>
                      {citiesData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol> */}
                  <CCol md={4}>
                    <CFormInput
                      id="residence_area"
                      type="text"
                      label="Residence Area"
                      placeholder="Enter residence area"
                      feedbackInvalid="Please provide a valid residence area"
                      value={formData?.residence_area}
                      onChange={(e) => setFormData({ ...formData, residence_area: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="hometown_living_status"
                      type="text"
                      label="Hometown Living Status"
                      placeholder="Enter hometown living status"
                      feedbackInvalid="Please provide a valid hometown living status"
                      value={formData?.hometown_living_status}
                      onChange={(e) =>
                        setFormData({ ...formData, hometown_living_status: e.target.value })
                      }
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="hometown_status"
                      type="text"
                      label="Hometown Status"
                      placeholder="Enter hometown status"
                      feedbackInvalid="Please provide a valid hometown status"
                      value={formData?.hometown_status}
                      onChange={(e) =>
                        setFormData({ ...formData, hometown_status: e.target.value })
                      }
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="hometown_size"
                      type="text"
                      label="Hometown Size"
                      placeholder="Enter hometown size"
                      feedbackInvalid="Please provide a valid hometown size"
                      value={formData?.hometown_size}
                      onChange={(e) => setFormData({ ...formData, hometown_size: e.target.value })}
                      required
                    />
                  </CCol>
                  {/* <CCol md={4}>
                    <CFormSelect
                      id="hometown_country"
                      label="Hometown Country"
                      feedbackInvalid="Please provide a valid hometown country"
                      value={formData?.hometown_country}
                      onChange={(e) => {
                        countryData?.map(
                          ({ name, isoCode }) => name === e.target.value && setCountryCode(isoCode),
                        )
                        setFormData({
                          ...formData,
                          hometown_country: e.target.value,
                          hometown_state: '',
                          hometown_city: '',
                        })
                      }}
                      required
                    >
                      <option>Select country</option>
                      {countryData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="hometown_state"
                      label="Hometown State"
                      feedbackInvalid="Please provide a valid hometown state"
                      value={formData?.hometown_state}
                      onChange={(e) => {
                        statesData?.map(
                          ({ name, isoCode }) => name === e.target.value && setStateCode(isoCode),
                        )
                        setFormData({
                          ...formData,
                          hometown_state: e.target.value,
                          hometown_city: '',
                        })
                      }}
                      required
                    >
                      <option>Select state</option>
                      {statesData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="hometown_city"
                      label="Hometown City"
                      feedbackInvalid="Please provide a valid hometown city"
                      value={formData?.hometown_city}
                      onChange={(e) => {
                        citiesData?.map(({ name }) => name === e.target.value)
                        setFormData({
                          ...formData,
                          hometown_city: e.target.value,
                        })
                      }}
                      required
                    >
                      <option>Select city</option>
                      {citiesData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol> */}
                  <CCol md={4}>
                    <CFormInput
                      id="hometown_area"
                      type="text"
                      label="Hometown Area"
                      placeholder="Enter hometown area"
                      feedbackInvalid="Please provide a valid hometown area"
                      value={formData?.hometown_area}
                      onChange={(e) => setFormData({ ...formData, hometown_area: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="profile_pic"
                      type="text"
                      label="Profile Picture"
                      placeholder="Enter profile picture URL"
                      feedbackInvalid="Please provide a valid profile picture URL"
                      value={formData?.profile_pic}
                      onChange={(e) => setFormData({ ...formData, profile_pic: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="seen_profile_pic"
                      label="Seen Profile Picture"
                      feedbackInvalid="Please provide a valid seen profile picture"
                      value={formData?.seen_profile_pic}
                      onChange={(e) =>
                        setFormData({ ...formData, seen_profile_pic: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select status</option>
                      {data?.SeenStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="seen_photos"
                      label="Seen Photos"
                      feedbackInvalid="Please provide a valid seen photos"
                      value={formData?.seen_photos}
                      onChange={(e) => setFormData({ ...formData, seen_photos: e.target.value })}
                      required
                    >
                      <option disabled>Select status</option>
                      {data?.SeenStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="my_selfie"
                      type="text"
                      label="My Selfie"
                      placeholder="Enter your selfie URL"
                      feedbackInvalid="Please provide a valid selfie URL"
                      value={formData?.my_selfie}
                      onChange={(e) => setFormData({ ...formData, my_selfie: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="front_id_card"
                      type="text"
                      label="Front ID Card"
                      placeholder="Enter front ID card URL"
                      feedbackInvalid="Please provide a valid front ID card URL"
                      value={formData?.front_id_card}
                      onChange={(e) => setFormData({ ...formData, front_id_card: e.target.value })}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="back_id_card"
                      type="text"
                      label="Back ID Card"
                      placeholder="Enter back ID card URL"
                      feedbackInvalid="Please provide a valid back ID card URL"
                      value={formData?.back_id_card}
                      onChange={(e) => setFormData({ ...formData, back_id_card: e.target.value })}
                      required
                    />
                  </CCol>
                </CCardBody>
              </CCard>
              <CCard className="px-4 mt-2">
                <CCardBody className="p-4 row g-3">
                  <CCol md={4}>
                    <CFormInput
                      id="partner_age_between"
                      type="text"
                      label="Partner Age Between"
                      placeholder="Enter partner age between"
                      feedbackInvalid="Please provide a valid partner age between"
                      value={formData?.partner_age_between}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_age_between: e.target.value })
                      }
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_marital_status"
                      label="Partner Marital Status"
                      feedbackInvalid="Please provide a valid partner marital status"
                      value={formData?.partner_marital_status}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_marital_status: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select partner marital status</option>
                      {data?.maritalStatusOptions.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="partner_nationality"
                      type="text"
                      label="Partner Nationality"
                      placeholder="Enter partner nationality"
                      feedbackInvalid="Please provide a valid partner nationality"
                      value={formData?.partner_nationality}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_nationality: e.target.value })
                      }
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_religion"
                      label="Partner Religion"
                      feedbackInvalid="Please provide a valid partner religion"
                      value={formData?.partner_religion}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_religion: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select partner religion</option>
                      {data?.ReligionData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_sect_firqa"
                      type="text"
                      label="Partner Sect/Firqa"
                      placeholder="Enter partner sect/firqa"
                      feedbackInvalid="Please provide a valid partner sect/firqa"
                      value={formData?.partner_sect_firqa}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_sect_firqa: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select partner sect/firqa</option>
                      {data?.SectData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_community"
                      label="Partner Community"
                      feedbackInvalid="Please provide a valid partner community"
                      value={formData?.partner_community}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_community: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select partner community</option>
                      {data?.CommunityData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_cast"
                      label="Partner Cast"
                      feedbackInvalid="Please provide a valid partner cast"
                      value={formData?.partner_cast}
                      onChange={(e) => setFormData({ ...formData, partner_cast: e.target.value })}
                      required
                    >
                      <option disabled>Select partner cast</option>
                      {data?.CasteData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_skintone"
                      label="Partner Skintone"
                      feedbackInvalid="Please provide a valid partner skintone"
                      value={formData?.partner_skintone}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_skintone: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select partner skintone</option>
                      {data?.skintoneArray.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="partner_any_disability"
                      type="text"
                      label="Partner Any Disability"
                      placeholder="Enter partner any disability"
                      feedbackInvalid="Please provide a valid partner any disability"
                      value={formData?.partner_any_disability}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_any_disability: e.target.value })
                      }
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_edu_level"
                      type="text"
                      label="Partner Education Level"
                      placeholder="Enter partner education level"
                      feedbackInvalid="Please provide a valid partner education level"
                      value={formData?.partner_edu_level}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_edu_level: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select partner education</option>
                      {data?.EducationLevelData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_degree"
                      label="Partner Degree"
                      feedbackInvalid="Please provide a valid partner degree"
                      value={formData?.partner_degree}
                      onChange={(e) => setFormData({ ...formData, partner_degree: e.target.value })}
                      required
                    >
                      <option disabled>Select partner degree</option>
                      {data?.DegreeTitleData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_working_sector"
                      label="Partner Working Sector"
                      feedbackInvalid="Please provide a valid partner working sector"
                      value={formData?.partner_working_sector}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_working_sector: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select partner working sector</option>
                      {data?.WorkingSectorData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormInput
                      id="partner_profession_job"
                      type="text"
                      label="Partner Profession/Job"
                      placeholder="Enter partner profession/job"
                      feedbackInvalid="Please provide a valid partner profession/job"
                      value={formData?.partner_profession_job}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_profession_job: e.target.value })
                      }
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_residence_status"
                      label="Partner Residence Status"
                      feedbackInvalid="Please provide a valid partner residence status"
                      value={formData?.partner_residence_status}
                      onChange={(e) =>
                        setFormData({ ...formData, partner_residence_status: e.target.value })
                      }
                      required
                    >
                      <option disabled>Select partner residence status</option>
                      {data?.ResidenceStatusData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  {/* <CCol md={4}>
                    <CFormSelect
                      id="partner_residence_country"
                      label="Partner Residence Country"
                      feedbackInvalid="Please provide a valid partner residence country"
                      value={formData?.partner_residence_country}
                      onChange={(e) => {
                        countryData?.map(
                          ({ name, isoCode }) => name === e.target.value && setCountryCode(isoCode),
                        )
                        setFormData({
                          ...formData,
                          partner_residence_country: e.target.value,
                          partner_residence_state: '',
                          partner_residence_city: '',
                        })
                      }}
                      required
                    >
                      <option>Select country</option>
                      {countryData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_residence_state"
                      label="Partner Residence State"
                      feedbackInvalid="Please provide a valid partner residence state"
                      value={formData?.partner_residence_state}
                      onChange={(e) => {
                        statesData?.map(
                          ({ name, isoCode }) => name === e.target.value && setStateCode(isoCode),
                        )
                        setFormData({
                          ...formData,
                          partner_residence_state: e.target.value,
                          partner_residence_city: '',
                        })
                      }}
                      required
                    >
                      <option>Select state</option>
                      {statesData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={4}>
                    <CFormSelect
                      id="partner_residence_city"
                      label="Partner Residence City"
                      feedbackInvalid="Please provide a valid partner residence city"
                      value={formData?.partner_residence_city}
                      onChange={(e) => {
                        citiesData?.map(({ name }) => name === e.target.value)
                        setFormData({
                          ...formData,
                          partner_residence_city: e.target.value,
                        })
                      }}
                      required
                    >
                      <option>Select city</option>
                      {citiesData?.map(({ name }, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol> */}

                  <CCol md={12}>
                    <div className="d-flex justify-content-end">
                      <CButton color="success" type="submit" className="text-white mx-2">
                        Update Profile
                      </CButton>
                    </div>
                  </CCol>
                </CCardBody>
              </CCard>
            </CForm>
          )}
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default Editusers
