import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const signupUserRequest = () => ({
  type: 'SIGNUP_USER_REQUEST',
})

export const signupUserSuccess = () => ({
  type: 'SIGNUP_USER_SUCCESS',
})

export const signupUserFailure = (error) => ({
  type: 'SIGNUP_USER_FAILURE',
  error,
})

export const verifyOtpRequest = () => ({
  type: 'VERIFY_OTP_REQUEST',
})

export const verifyOtpSuccess = (user) => ({
  type: 'VERIFY_OTP_SUCCESS',
  user,
})

export const verifyOtpFailure = (error) => ({
  type: 'VERIFY_OTP_FAILURE',
  error,
})

export const registerUserRequest = () => ({
  type: 'REGISTER_USER_REQUEST',
})

export const registerUserSuccess = () => ({
  type: 'REGISTER_USER_SUCCESS',
})

export const registerUserFailure = (error) => ({
  type: 'REGISTER_USER_FAILURE',
  error,
})

export const signupAppUser = (body) => {
  return (dispatch) => {
    dispatch(signupUserRequest())

    fetch(URL.BASE_URL + 'signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((user) => {
        dispatch(signupUserSuccess())
        toast(user.message, { type: 'success' })
      })
      .catch((error) => {
        dispatch(signupUserFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const verifyOtp = (body) => {
  return (dispatch) => {
    dispatch(verifyOtpRequest())

    fetch(URL.BASE_URL + 'verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((user) => {
        dispatch(verifyOtpSuccess(user))
        toast(user.message, { type: 'success' })
      })
      .catch((error) => {
        dispatch(verifyOtpFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const registerUser = (body, userId, navigate) => {
  return (dispatch) => {
    dispatch(registerUserRequest())

    fetch(URL.BASE_URL + URL.END_POINT.registration, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: userId,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: body,
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(registerUserSuccess())
        toast('User registered successfully', { type: 'success' })
        navigate(-1)
      })
      .catch((error) => {
        dispatch(registerUserFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialSignupState = {
  signupLoading: false,
  signupError: null,
}

export const signupAppReducer = (state = initialSignupState, action) => {
  switch (action.type) {
    case 'SIGNUP_USER_REQUEST':
      return {
        ...state,
        signupLoading: true,
        signupError: null,
      }
    case 'SIGNUP_USER_SUCCESS':
      return {
        ...state,
        isOtpSend: true,
        signupLoading: false,
        signupError: null,
      }
    case 'SIGNUP_USER_FAILURE':
      return {
        ...state,
        signupLoading: false,
        signupError: action.error,
      }
    default:
      return state
  }
}

const initialVerifyOtpState = {
  verifyOtpLoading: false,
  verifyOtpError: null,
}

export const verifyOtpReducer = (state = initialVerifyOtpState, action) => {
  switch (action.type) {
    case 'VERIFY_OTP_REQUEST':
      return {
        ...state,
        verifyOtpLoading: true,
        verifyOtpError: null,
      }
    case 'VERIFY_OTP_SUCCESS':
      return {
        ...state,
        isVerifyOtp: true,
        userId: action.user.USERID,
        verifyOtpLoading: false,
        verifyOtpError: null,
      }
    case 'VERIFY_OTP_FAILURE':
      return {
        ...state,
        verifyOtpLoading: false,
        verifyOtpError: action.error,
      }
    default:
      return state
  }
}

const initialAppState = {
  registrationLoading: false,
  registrationError: null,
}

export const registrationReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'REGISTER_USER_REQUEST':
      return {
        ...state,
        registrationLoading: true,
        registrationError: null,
      }
    case 'REGISTER_USER_SUCCESS':
      return {
        ...state,
        registrationLoading: false,
        registrationError: null,
      }
    case 'REGISTER_USER_FAILURE':
      return {
        ...state,
        registrationLoading: false,
        registrationError: action.error,
      }
    default:
      return state
  }
}
