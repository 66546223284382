import React from 'react'
import { CContainer } from '@coreui/react'

const TnCs = () => {
  return (
    <CContainer>
      <h2>Terms & Conditions</h2>

      <p>
        By downloading or using the app, these terms will automatically apply to you – you should
        make sure therefore that you read them carefully before using the app. You’re not allowed to
        copy or modify the app, any part of the app, or our trademarks in any way. You’re not
        allowed to attempt to extract the source code of the app, and you also shouldn’t try to
        translate the app into other languages or make derivative versions. The app itself, and all
        the trademarks, copyright, database rights, and other intellectual property rights related
        to it, still belong to Nikkah.
      </p>

      <p>
        Nikkah is committed to ensuring that the app is as useful and efficient as possible. For
        that reason, we reserve the right to make changes to the app or to charge for its services,
        at any time and for any reason. We will never charge you for the app or its services without
        making it very clear to you exactly what you’re paying for.
      </p>

      <p>
        The Nikkah app stores and processes personal data that you have provided to us, to provide
        my Service. It’s your responsibility to keep your phone and access to the app secure. We
        therefore recommend that you do not jailbreak or root your phone, which is the process of
        removing software restrictions and limitations imposed by the official operating system of
        your device. It could make your phone vulnerable to malware/viruses/malicious programs,
        compromise your phone’s security features and it could mean that the Nikkah app won’t work
        properly or at all.
      </p>

      <h5>Third-Party Services</h5>
      <p>The app does use third-party services that declare their Terms and Conditions.</p>
      <h5>Link to Terms and Conditions of third-party service providers used by the app:</h5>
      <ul>
        <li>
          <a
            href="https://play.google.com/store/apps/details?id=com.nikkah"
            rel="noreferrer"
            target="_blank"
          >
            Google Play Services
          </a>
        </li>
      </ul>

      <p>
        You should be aware that there are certain things that Nikkah will not take responsibility
        for. Certain functions of the app will require the app to have an active internet
        connection. The connection can be Wi-Fi or provided by your mobile network provider, but
        Nikkah cannot take responsibility for the app not working at full functionality if you don’t
        have access to Wi-Fi, and you don’t have any of your data allowance left.
      </p>

      <h5>Changes to This Terms and Conditions</h5>

      <p>
        I may update our Terms and Conditions from time to time. Thus, you are advised to review
        this page periodically for any changes. I will notify you of any changes by posting the new
        Terms and Conditions on this page. These terms and conditions are effective as of 2024-01-22
      </p>

      <h5>Contact Us</h5>

      <p>
        If you have any questions or suggestions about my Terms and Conditions, do not hesitate to
        contact me at <a href="mailto:1nikkah@gmail.com">1nikkah@gmail.com</a>.
      </p>
    </CContainer>
  )
}

export default TnCs
