import React, { useEffect, useState } from 'react'

import { CButton, CCard, CCardBody, CCardFooter, CCol, CProgress, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser, cilUserFemale, cilFilterPhoto, cilEnvelopeClosed, cilMobile } from '@coreui/icons'

import { useDispatch, useSelector } from 'react-redux'
import { getDashboard } from 'src/redux/dashboard'
import { getSubscriptionData } from 'src/redux/subscription'
import { getPackageHistory } from 'src/redux/packages'

const Dashboard = () => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const {
    todayRegister,
    totalRegister,
    todayProposals,
    totalProposals,
    thisMonthProposals,
    thisYearProposals,
    thisMonthRegister,
    thisYearRegister,
    progressProposal,
    progressRequest,
    progressGender,
  } = useSelector((state) => state.admindashboard)
  const { subscriptionData } = useSelector((state) => state.subscription)
  const { packages } = useSelector((state) => state.packages)

  useEffect(() => {
    dispatch(getDashboard())
    dispatch(getSubscriptionData())
    dispatch(getPackageHistory())
  }, [dispatch, loading])

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h5 className="card-title mb-0">Proposal Request</h5>
            </CCol>
            <CCol sm={7} className="d-none d-md-block"></CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {progressProposal?.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} Request ({parseInt(item.percent)}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter>
      </CCard>

      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <h5 className="card-title m-3">Registration{' & '}Proposals</h5>
            <CCardBody>
              <CRow>
                <CCol xs={12} md={6} xl={6}>
                  <CRow>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-info py-1 px-3">
                        <CButton color="outline-secondary" className="mx-0" size="sm">
                          <div className="text-medium-emphasis">Today Register</div>
                        </CButton>

                        <div className="fs-5 fw-semibold">{todayRegister}</div>
                      </div>
                    </CCol>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-danger py-1 px-3 mb-3">
                        <CButton color="outline-secondary" className="mx-0" size="sm">
                          <div className="text-medium-emphasis">Total Register</div>
                        </CButton>
                        <div className="fs-5 fw-semibold">{totalRegister}</div>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol sm={6}>
                      <div className="border border-2 border-info py-1 px-3">
                        <div className="text-medium-emphasis small">Current Month Register</div>
                        <div className="fs-5 fw-semibold">{thisMonthRegister}</div>
                      </div>
                    </CCol>
                    <CCol sm={6}>
                      <div className="border border-2 border-danger py-1 px-3 mb-3">
                        <div className="text-medium-emphasis small">Current Year Register</div>
                        <div className="fs-5 fw-semibold">{thisYearRegister}</div>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-primary py-1 px-3">
                        <CButton color="outline-secondary" className="mx-0" size="sm">
                          <div className="text-medium-emphasis">Total Subscription</div>
                        </CButton>
                        <div className="fs-5 fw-semibold">{subscriptionData?.package?.length}</div>
                      </div>
                    </CCol>
                  </CRow>

                  <hr className="" />
                  {progressRequest?.map((item, index) => (
                    <div className="progress-group" key={index}>
                      <div className="progress-group-header">
                        <CIcon
                          className="me-2"
                          icon={
                            item.icon === 'cilFilterPhoto'
                              ? cilFilterPhoto
                              : item.icon === 'cilEnvelopeClosed'
                              ? cilEnvelopeClosed
                              : item.icon === 'cilMobile'
                              ? cilMobile
                              : null
                          }
                          size="lg"
                        />
                        <span>{item.title}</span>
                        <span className="ms-auto fw-semibold">
                          {item.value}{' '}
                          <span className="text-medium-emphasis small">
                            ({parseInt(item.percent)}%)
                          </span>
                        </span>
                      </div>
                      <div className="progress-group-bars">
                        <CProgress thin color="success" value={item.percent} />
                      </div>
                    </div>
                  ))}
                </CCol>

                <CCol xs={12} md={6} xl={6}>
                  <CRow>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-warning py-1 px-3 mb-3">
                        <CButton color="outline-secondary" className="mx-0" size="sm">
                          <div className="text-medium-emphasis">Today Proposal</div>
                        </CButton>
                        <div className="fs-5 fw-semibold">{todayProposals}</div>
                      </div>
                    </CCol>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                        <CButton color="outline-secondary" className="mx-0" size="sm">
                          <div className="text-medium-emphasis">Total Proposal</div>
                        </CButton>
                        <div className="fs-5 fw-semibold">{totalProposals}</div>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol sm={6}>
                      <div className="border border-2 border-warning py-1 px-3 mb-3">
                        <div className="text-medium-emphasis small">Current Month Proposal</div>
                        <div className="fs-5 fw-semibold">{thisMonthProposals}</div>
                      </div>
                    </CCol>
                    <CCol sm={6}>
                      <div className="border border-2 border-success py-1 px-3 mb-3">
                        <div className="text-medium-emphasis small">Current Year Proposal</div>
                        <div className="fs-5 fw-semibold">{thisYearProposals}</div>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-primary py-1 px-3">
                        <CButton color="outline-secondary" className="mx-0" size="sm">
                          <div className="text-medium-emphasis ">Total Package Approval</div>
                        </CButton>
                        <div className="fs-5 fw-semibold">{packages?.history?.length}</div>
                      </div>
                    </CCol>
                  </CRow>

                  <hr className="" />

                  {progressGender?.map((item, index) => (
                    <div className="progress-group mb-4" key={index}>
                      <div className="progress-group-header">
                        <CIcon
                          className="me-2"
                          icon={
                            item.icon === 'cilUser'
                              ? cilUser
                              : item.icon === 'cilUserFemale'
                              ? cilUserFemale
                              : null
                          }
                          size="lg"
                        />
                        <span>{item.title}</span>
                        <span className="ms-auto fw-semibold">
                          {item.value}{' '}
                          <span className="text-medium-emphasis small">
                            ({parseInt(item.percent)}%)
                          </span>
                        </span>
                      </div>
                      <div className="progress-group-bars">
                        <CProgress thin color={item.color} value={item.percent} />
                      </div>
                    </div>
                  ))}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default Dashboard
