import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getBankDetailsRequest = () => ({
  type: 'GET_BANK_DETAILS_REQUEST',
})

export const getBankDetailsSuccess = (data) => ({
  type: 'GET_BANK_DETAILS_SUCCESS',
  data,
})

export const getBankDetailsFailure = (error) => ({
  type: 'GET_BANK_DETAILS_FAILURE',
  error,
})

export const addBankDetailsRequest = () => ({
  type: 'ADD_BANK_DETAILS_REQUEST',
})

export const addBankDetailsSuccess = () => ({
  type: 'ADD_BANK_DETAILS_SUCCESS',
})

export const addBankDetailsFailure = (error) => ({
  type: 'ADD_BANK_DETAILS_FAILURE',
  error,
})

export const editBankDetailsRequest = () => ({
  type: 'EDIT_BANK_DETAILS_REQUEST',
})

export const editBankDetailsSuccess = () => ({
  type: 'EDIT_BANK_DETAILS_SUCCESS',
})

export const editBankDetailsFailure = (error) => ({
  type: 'EDIT_BANK_DETAILS_FAILURE',
  error,
})

export const deleteBankDetailsRequest = () => ({
  type: 'DELETE_BANK_DETAILS_REQUEST',
})

export const deleteBankDetailsSuccess = () => ({
  type: 'DELETE_BANK_DETAILS_SUCCESS',
})

export const deleteBankDetailsFailure = (error) => ({
  type: 'DELETE_BANK_DETAILS_FAILURE',
  error,
})

export const getBankDetails = () => {
  return (dispatch) => {
    dispatch(getBankDetailsRequest())

    fetch(URL.BASE_URL + URL.END_POINT.bankdetails, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getBankDetailsSuccess(data))
      })
      .catch((error) => {
        dispatch(getBankDetailsFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const addBankDetails = (body) => {
  return (dispatch) => {
    dispatch(addBankDetailsRequest())

    fetch(URL.BASE_URL + URL.END_POINT.bankdetails, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: body,
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(addBankDetailsSuccess())
        toast('Bank details added successfully', { type: 'success' })
        dispatch(getBankDetails())
      })
      .catch((error) => {
        dispatch(addBankDetailsFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const editBankDetails = (id, body) => {
  return (dispatch) => {
    dispatch(editBankDetailsRequest())

    fetch(URL.BASE_URL + URL.END_POINT.bankdetails + id, {
      method: 'PUT',
      headers: {
        // 'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: body,
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(editBankDetailsSuccess())
        toast('Bank details updated successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(editBankDetailsFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteBankDetails = (id) => {
  return (dispatch) => {
    dispatch(deleteBankDetailsRequest())

    fetch(URL.BASE_URL + URL.END_POINT.bankdetails + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(deleteBankDetailsSuccess())
        toast('Bank details deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteBankDetailsFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  bankDetails: [],
  loadingBankDetails: false,
  errorBankDetails: null,
}

export const bankDetailsReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_BANK_DETAILS_REQUEST':
    case 'ADD_BANK_DETAILS_REQUEST':
    case 'EDIT_BANK_DETAILS_REQUEST':
    case 'DELETE_BANK_DETAILS_REQUEST':
      return {
        ...state,
        loadingBankDetails: true,
        errorBankDetails: null,
      }
    case 'GET_BANK_DETAILS_SUCCESS':
      return {
        ...state,
        bankDetails: action.data,
        loadingBankDetails: false,
        errorBankDetails: null,
      }
    case 'ADD_BANK_DETAILS_SUCCESS':
    case 'EDIT_BANK_DETAILS_SUCCESS':
    case 'DELETE_BANK_DETAILS_SUCCESS':
      return {
        ...state,
        loadingBankDetails: false,
        errorBankDetails: null,
      }
    case 'GET_BANK_DETAILS_FAILURE':
    case 'ADD_BANK_DETAILS_FAILURE':
    case 'EDIT_BANK_DETAILS_FAILURE':
    case 'DELETE_BANK_DETAILS_FAILURE':
      return {
        ...state,
        loadingBankDetails: false,
        errorBankDetails: action.error,
      }
    default:
      return state
  }
}
