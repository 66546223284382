import {
  cilAddressBook,
  cilBellExclamation,
  cilFingerprint,
  cilGroup,
  cilMobile,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
} from '@coreui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

const AddSpecialUser = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    gender: 'Male',
    status: '',
    city: '',
    age: '',
  })
  const [users, setUsers] = useState([])
  const [selectUser, setSelectUser] = useState('')

  useEffect(() => {
    const url = URL.BASE_URL + URL.END_POINT.appusers
    fetch(`${url}?limit=100`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setUsers(data.app_users))
      .catch((error) => console.log(error))
  }, [])
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(URL.BASE_URL + URL.END_POINT.createSpecialUser, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          USERID: localStorage.getItem('USERID'),
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(formData),
      })

      if (!response.ok) {
        const message = await response.json().then((res) => res.message)
        throw new Error(message)
      }
      toast('Special User created successfully! ', { type: 'success' })

      setFormData({
        name: '',
        phone_number: '',
        gender: 'male',
        status: '',
        city: '',
        age: '',
      })
      navigate('/users/special')
    } catch (error) {
      toast(error.message, { type: 'error' })
    }
  }

  const userList = useMemo(
    () => users.map((user, index) => ({ label: user?.email_id, value: index })),
    [users],
  )

  const handleOnChange = (event) => {
    let data = users[event.target.value]
    console.log(data, '[data]')
    setSelectUser(event.target.value)
    setFormData({
      age: data?.age,
      city: data?.city,
      gender: data?.sex,
      name: data?.name,
      phone_number: data?.mobile_no,
      status: data?.martial_status,
      user_code: data?.user_code,
    })
  }

  return (
    <CContainer>
      {/* <UserList setFormData={setFormData} /> */}
      <CCard>
        <CCardBody className="p-4">
          <h1 className="mb-3">Add Special User</h1>

          <div className="mb-3">
            {/* <option value="">Select Option</option>
              {userList.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))} */}
            <CFormSelect
              value={selectUser}
              onChange={handleOnChange}
              options={[{ label: 'Select Option', value: '', disabled: true }, ...userList]}
            ></CFormSelect>
            <p style={{ fontSize: '12px' }}>Select from database</p>
          </div>
          <CForm>
            <CInputGroup className="mb-3">
              <CInputGroupText>
                <CIcon icon={cilUser} />
              </CInputGroupText>
              <CFormInput
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText>
                <CIcon icon={cilMobile} />
              </CInputGroupText>
              <CFormInput
                name="phone_number"
                placeholder="Mobile"
                value={formData.phone_number}
                onChange={handleChange}
              />
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText>
                <CIcon icon={cilGroup} />
              </CInputGroupText>
              <CFormSelect name="gender" value={formData.gender} onChange={handleChange}>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </CFormSelect>
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText>
                <CIcon icon={cilBellExclamation} />
              </CInputGroupText>
              <CFormInput
                name="status"
                placeholder="Status"
                value={formData.status}
                onChange={handleChange}
              />
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText>
                <CIcon icon={cilAddressBook} />
              </CInputGroupText>
              <CFormInput
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleChange}
              />
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText>
                <CIcon icon={cilFingerprint} />
              </CInputGroupText>
              <CFormInput
                name="age"
                placeholder="Age"
                value={formData.age}
                onChange={handleChange}
              />
            </CInputGroup>
            <div className="d-grid mb-3">
              <CButton color="success" onClick={handleSubmit}>
                Create Account
              </CButton>
            </div>
          </CForm>
        </CCardBody>
      </CCard>
    </CContainer>
  )
}

export default AddSpecialUser
