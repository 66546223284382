import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getReportDataRequest = () => ({
  type: 'GET_REPORT_DATA_REQUEST',
})

export const getReportDataSuccess = (data) => ({
  type: 'GET_REPORT_DATA_SUCCESS',
  data,
})

export const getReportDataFailure = (error) => ({
  type: 'GET_REPORT_DATA_FAILURE',
  error,
})

export const getReportData = () => {
  return (dispatch) => {
    dispatch(getReportDataRequest())

    fetch(URL.BASE_URL + URL.END_POINT.reportdata, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getReportDataSuccess(data))
      })
      .catch((error) => {
        dispatch(getReportDataFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  reportData: [],
  loadingReportData: false,
  errorReportData: null,
}

const reportReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_REPORT_DATA_REQUEST':
      return {
        ...state,
        loadingReportData: true,
        errorReportData: null,
      }
    case 'GET_REPORT_DATA_SUCCESS':
      return {
        ...state,
        reportData: action.data,
        loadingReportData: false,
        errorReportData: null,
      }
    case 'GET_REPORT_DATA_FAILURE':
      return {
        ...state,
        loadingReportData: false,
        errorReportData: action.error,
      }
    default:
      return state
  }
}

export default reportReducer
