import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getSpecialUsersRequest = () => ({
  type: 'GET_SPECIAL_USERS_REQUEST',
})

export const getSpecialUsersSuccess = (users) => ({
  type: 'GET_SPECIAL_USERS_SUCCESS',
  users,
})

export const getSpecialUsersFailure = (error) => ({
  type: 'GET_SPECIAL_USERS_FAILURE',
  error,
})

export const deleteSpecialUserRequest = () => ({
  type: 'DELETE_SPECIAL_USER_REQUEST',
})

export const deleteSpecialUserSuccess = () => ({
  type: 'DELETE_SPECIAL_USER_SUCCESS',
})

export const deleteSpecialUserFailure = (error) => ({
  type: 'DELETE_SPECIAL_USER_FAILURE',
  error,
})

// export const deleteMultipleAppUsersRequest = () => ({
//   type: 'DELETE_SPECIAL_USERS_REQUEST',
// })

// export const deleteMultipleAppUsersSuccess = () => ({
//   type: 'DELETE_SPECIAL_USERS_SUCCESS',
// })

// export const deleteMultipleAppUsersFailure = (error) => ({
//   type: 'DELETE_SPECIAL_USERS_FAILURE',
//   error,
// })

export const getSpecialUsers = (filter, page = 1, status = '', search = '') => {
  return (dispatch) => {
    dispatch(getSpecialUsersRequest())
    let url = URL.BASE_URL + URL.END_POINT.getSpecialUsers

    let obj = {}
    if (filter) obj.filter = filter
    if (page) obj.page = page
    if (status !== '') obj.status = status
    if (search !== '') obj.search = search

    Object.keys(obj).forEach((key, i) => {
      if (i === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((users) => {
        dispatch(getSpecialUsersSuccess(users))
      })
      .catch((error) => {
        dispatch(getSpecialUsersFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteSpecialUser = (userId) => {
  return (dispatch) => {
    dispatch(deleteSpecialUserRequest())

    fetch(URL.BASE_URL + URL.END_POINT.deleteSpecialUser + userId, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(deleteSpecialUserSuccess())
        toast('Special User deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteSpecialUserFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialSpecialUsersState = {
  specialUsers: [],
  loadingSpecialUsers: false,
  errorSpecialUsers: null,
}

export const specialUsersReducer = (state = initialSpecialUsersState, action) => {
  switch (action.type) {
    case 'GET_SPECIAL_USERS_REQUEST':
      return {
        ...state,
        loadingSpecialUsers: true,
        errorSpecialUsers: null,
      }
    case 'GET_SPECIAL_USERS_SUCCESS':
      return {
        ...state,
        specialUsers: action.users,
        loadingSpecialUsers: false,
        errorSpecialUsers: null,
      }
    case 'GET_SPECIAL_USERS_FAILURE':
      return {
        ...state,
        loadingSpecialUsers: false,
        errorSpecialUsers: action.error,
      }
    default:
      return state
  }
}
