import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const signupRequest = () => ({
  type: 'SIGNUP_REQUEST',
})

export const signupSuccess = (user) => ({
  type: 'SIGNUP_SUCCESS',
  user,
})

export const signupFailure = (error) => ({
  type: 'SIGNUP_FAILURE',
  error,
})

export const deleteAdminRequest = () => ({
  type: 'DELETE_ADMIN_REQUEST',
})

export const deleteAdminSuccess = () => ({
  type: 'DELETE_ADMIN_SUCCESS',
})

export const deleteAdminFailure = (error) => ({
  type: 'DELETE_ADMIN_FAILURE',
  error,
})

export const updateAdminRequest = () => ({
  type: 'UPDATE_ADMIN_REQUEST',
})

export const updateAdminSuccess = () => ({
  type: 'UPDATE_ADMIN_SUCCESS',
})

export const updateAdminFailure = (error) => ({
  type: 'UPDATE_ADMIN_FAILURE',
  error,
})

export const signupUser = (userData) => {
  return (dispatch) => {
    dispatch(signupRequest())

    fetch(URL.BASE_URL + URL.END_POINT.signup, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: userData,
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((user) => {
        dispatch(signupSuccess(user))
        toast(user.message, { type: 'success' })
      })
      .catch((error) => {
        dispatch(signupFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteAdminUser = (id) => {
  return (dispatch) => {
    dispatch(deleteAdminRequest())

    fetch(URL.BASE_URL + URL.END_POINT.adminusers + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then(() => {
        dispatch(deleteAdminSuccess())
        toast('Admin user deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteAdminFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}
export const updateAdminUser = (data) => {
  return (dispatch) => {
    dispatch(updateAdminRequest())

    fetch(URL.BASE_URL + URL.END_POINT.adminusers + data?.id, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then(() => {
        dispatch(updateAdminSuccess())
        toast('Admin user update successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(updateAdminFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialSignupState = {
  isSignedUp: false,
  user: null,
  loading: false,
  error: null,
}

const signupReducer = (state = initialSignupState, action) => {
  switch (action.type) {
    case 'SIGNUP_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        isSignedUp: true,
        user: action.user,
        loading: false,
        error: null,
      }
    case 'SIGNUP_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default signupReducer

const initialDeleteAdminState = {
  isDeleted: false,
  loading: false,
  error: null,
}

export const deleteAdminReducer = (state = initialDeleteAdminState, action) => {
  switch (action.type) {
    case 'DELETE_ADMIN_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'DELETE_ADMIN_SUCCESS':
      return {
        ...state,
        isDeleted: true,
        loading: false,
        error: null,
      }
    case 'DELETE_ADMIN_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
