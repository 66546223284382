import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getAdminUsersRequest = () => ({
  type: 'GET_ADMIN_USERS_REQUEST',
})

export const getAdminUsersSuccess = (users) => ({
  type: 'GET_ADMIN_USERS_SUCCESS',
  users,
})

export const getAdminUsersFailure = (error) => ({
  type: 'GET_ADMIN_USERS_FAILURE',
  error,
})

export const getAdminUsers = () => {
  return (dispatch) => {
    dispatch(getAdminUsersRequest())

    fetch(URL.BASE_URL + URL.END_POINT.adminusers, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((users) => {
        dispatch(getAdminUsersSuccess(users))
      })
      .catch((error) => {
        dispatch(getAdminUsersFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  adminUsers: [],
  loadingAdminUsers: false,
  errorAdminUsers: null,
}

const adminReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_ADMIN_USERS_REQUEST':
      return {
        ...state,
        loadingAdminUsers: true,
        errorAdminUsers: null,
      }
    case 'GET_ADMIN_USERS_SUCCESS':
      return {
        ...state,
        adminUsers: action.users,
        loadingAdminUsers: false,
        errorAdminUsers: null,
      }
    case 'GET_ADMIN_USERS_FAILURE':
      return {
        ...state,
        loadingAdminUsers: false,
        errorAdminUsers: action.error,
      }
    default:
      return state
  }
}

export default adminReducer
