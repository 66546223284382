export function DateTimeConverter(date) {
  const originalDateTime = new Date(date)
  const formattedDateTime = originalDateTime.toLocaleString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
  return formattedDateTime
}
