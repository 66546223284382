import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormTextarea,
  CImage,
  CRow,
} from '@coreui/react'
import contactUsImage from '../../../assets/images/contactus.png'
import URL from 'src/apis/URL'
import { toast } from 'react-toastify'

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    fetch(URL.BASE_URL + URL.END_POINT.contactus, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Something went wrong')
        }
        return response.json()
      })
      .then((data) => {
        if (data && data.success) {
          toast(data.message)
          setFormData({
            name: '',
            email: '',
            message: '',
          })
        }
      })
      .catch((err) => {
        toast('Something went wrong')
      })
  }

  return (
    <div style={{ height: '100vh' }}>
      <CContainer className="h-100 d-flex justify-content-center align-items-center">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol lg={12}>
                <h2>Contact Us</h2>
              </CCol>
              <CCol md={6}>
                <CForm className="col g-3" onSubmit={handleSubmit}>
                  <CFormInput
                    className="mb-4"
                    type="text"
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="John Doe"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <CFormInput
                    className="mb-4"
                    type="email"
                    id="email"
                    name="email"
                    label="Email address"
                    placeholder="johndoe@example.com"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <CFormTextarea
                    className="mb-4"
                    id="message"
                    name="message"
                    label="Message"
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <CButton color="primary" size="lg" type="submit" className="mb-3 w-100">
                    Send
                  </CButton>
                </CForm>
              </CCol>
              <CCol md={6}>
                <CImage fluid src={contactUsImage} />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CContainer>
    </div>
  )
}

export default ContactUs
