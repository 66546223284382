import React, { useEffect, useState } from 'react'
import { CButton, CCol, CForm, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { loginUser } from 'src/redux/login'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const handleLogin = async (e) => {
    e.preventDefault()
    if (!email) {
      toast('Email required!', { type: 'info' })
    } else if (!password) {
      toast('Password required!', { type: 'info' })
    } else {
      setLoading(true)
      const credentials = {
        email_id: email,
        password: password,
      }
      try {
        await dispatch(loginUser(credentials))
      } catch (error) {
        toast(error.message, { type: 'error' })
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {}, [loading])

  return (
    <CForm noValidate onSubmit={handleLogin}>
      <h1>Login</h1>
      <p className="text-medium-emphasis">Sign In to your account</p>
      <CInputGroup className="mb-3">
        <CInputGroupText>
          <CIcon icon={cilUser} />
        </CInputGroupText>
        <CFormInput
          placeholder="Username"
          autoComplete="username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </CInputGroup>
      <CInputGroup className="mb-4">
        <CInputGroupText>
          <CIcon icon={cilLockLocked} />
        </CInputGroupText>
        <CFormInput
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </CInputGroup>
      <CRow>
        <CCol xs={6}>
          <CButton color="info" className="px-4 text-white" type="submit">
            Login
          </CButton>
        </CCol>
        <CCol xs={6} className="text-right">
          <CButton
            color="link"
            className="px-0"
            onClick={() => {
              navigate('/forgot')
            }}
          >
            Forgot password?
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  )
}

export default Login
